import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware, { SagaMiddlewareOptions } from 'redux-saga'
import logger from 'redux-logger'
import { rootReducer } from './reducers'
import { rootSaga } from './sagas'
import * as Sentry from '@sentry/react'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
})

const configureStore = (initialState = {}) => {
  const sagaMiddlewareOptions: SagaMiddlewareOptions = {}

  const saga = createSagaMiddleware(sagaMiddlewareOptions)
  const store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(saga), applyMiddleware(logger), sentryReduxEnhancer),
  )

  saga.run(rootSaga)

  return {
    ...store,
  }
}

export const store = configureStore()
