import React, { memo, useCallback, useMemo } from 'react'
import tw from 'twin.macro'
import { Modal, Typography } from '@mui/material'
import { colors } from 'src/theme'
import { useDispatch } from 'react-redux'
import { Logout } from 'src/constants/actionTypes'
import { Button } from '../shared/Button'
import { useNavigate } from 'react-router-dom'
import { ONBOARDING_ROUTES, RECOLLECTION_ROUTES } from 'src/constants/routes'

interface Props {
  flow: 'onboarding' | 'recollect'
  isOpen: boolean
  onClose: () => void
}

const LogoutModalComponent = ({ flow, isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const container = useMemo(() => document.getElementById('root'), [])

  const handleLogout = useCallback(() => {
    dispatch(Logout.request())

    if (flow === 'onboarding') {
      navigate(ONBOARDING_ROUTES.LOGGED_OUT_URL)
    }
    if (flow === 'recollect') {
      navigate(RECOLLECTION_ROUTES.LOGGED_OUT_URL)
    }
  }, [flow])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex flex-col justify-end xsm:justify-center items-center backdrop-blur`}
    >
      <div
        css={tw`flex-col p-4 pt-6 bg-white rounded-2xl rounded-b-none xsm:rounded-b-2xl w-full xsm:w-[390px]`}
      >
        <Typography variant="h2">Log out</Typography>
        <Typography css={tw`mt-2 mb-6`} color={colors.SHADES_GREY_400}>
          Are you sure you want to log out? Your progress will be saved, and you can log in to
          continue where you left off at any time.
        </Typography>
        <Button
          fullWidth
          color="red"
          onClick={handleLogout}
          className={tw`bg-[#EDEDED] hover:bg-[#DAD9E0] active:bg-[#B4AFC0]`}
        >
          Log out
        </Button>
        <Button fullWidth onClick={onClose} color="black" className={tw`mt-3`}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

export const LogoutModal = memo(LogoutModalComponent)
