import { memo, useCallback, useEffect, useRef, useState } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { showToast } from 'src/utils/toast'
import { DateTime } from 'luxon'

interface Props {
  disabled?: boolean
  onResend(): void
}

const RESEND_TIMEOUT = 1000 * 45

const ResendCodeComponent = ({ disabled, onResend }: Props) => {
  // const timeLeftToTryAgain = useRef(RESEND_TIMEOUT)
  const interval = useRef<NodeJS.Timer | null>(null)

  const [time, setTime] = useState<number>(0)

  useEffect(() => {
    if (time <= 0 && interval.current) {
      clearInterval(interval.current)

      interval.current = null
    }

    if (interval.current || !time) {
      return
    }

    interval.current = setInterval(() => {
      setTime((prev) => prev - 1000)
    }, 1000)
  }, [time])

  const handleResend = useCallback(() => {
    if (disabled || time) {
      return
    }

    // if (timeLeftToTryAgain.current > 0) {
    //   showToast(`You can resend code in ${timeLeftToTryAgain.current / 1000} seconds`)

    //   return
    // }

    // timeLeftToTryAgain.current = RESEND_TIMEOUT

    setTime(RESEND_TIMEOUT)

    onResend()

    showToast('New code has been sent', { variant: 'success' })
  }, [disabled, time, onResend])

  return (
    <Typography css={[tw`flex flex-row justify-center`]} color={colors.GRAY_DARK_COOL}>
      Didn't receive it? &nbsp;
      <Typography
        css={time ? undefined : tw`cursor-pointer`}
        onClick={time ? undefined : handleResend}
        variant="h4"
        color={time ? colors.GRAY_BLACK : colors.BRAND_BLUE}
      >
        {time ? `Resend new in ${DateTime.fromMillis(time).toFormat('mm:ss')}` : 'Tap to resend'}
      </Typography>
    </Typography>
  )
}

export const ResendCode = memo(ResendCodeComponent)
