import { memo } from 'react'
import tw from 'twin.macro'
import EmptyCheckIcon from 'src/assets/icons/empty-check.svg'
import { TickMinor } from '@shopify/polaris-icons'

interface Props {
  selected?: boolean
}

const CheckboxComponent = ({ selected }: Props) =>
  selected ? (
    <div css={tw`flex  items-center justify-center bg-black w-5 h-5 rounded`}>
      <TickMinor fill="white" width={20} />
    </div>
  ) : (
    <img src={EmptyCheckIcon} />
  )

export const Checkbox = memo(CheckboxComponent)
