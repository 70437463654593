import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'

interface Props {
  css?: any
}

const TOSComponent = ({ ...rest }: Props) => (
  <div {...rest}>
    <Typography css={[tw`text-center`]} color={colors.GRAY_DARK_COOL}>
      By continuing, you are
    </Typography>
    <Typography css={[tw`text-center`]} color={colors.GRAY_DARK_COOL}>
      agreeing to our &nbsp;
      <a css={tw`no-underline`} href="https://www.gigsafe.com/terms" target="_blank">
        <Typography
          css={tw`cursor-pointer underline inline-block`}
          variant="h4"
          color={colors.BRAND_BLUE}
        >
          Terms of Service
        </Typography>
      </a>
    </Typography>
  </div>
)

export const TOS = memo(TOSComponent)
