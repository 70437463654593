import React, { memo } from 'react'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { VaccinationForm } from 'src/components/recollection/vaccination-form/VaccinationForm'

const VaccinationPageComponent = () => {
  return (
    <RecollectionSecureWrapper>
      <VaccinationForm />
    </RecollectionSecureWrapper>
  )
}

export const VaccinationPage = memo(VaccinationPageComponent)
