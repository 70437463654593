import { Typography } from '@mui/material'
import React, { memo, useCallback } from 'react'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { CircleRightMajor } from '@shopify/polaris-icons'
import MVRIcon from 'src/assets/icons/bgc.svg'
import { OnboardingStepType } from 'src/constants/onboarding'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { useSelector } from 'react-redux'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { MVROnboardingStep } from 'src/interfaces/onboarding'
import { useYardstik } from 'src/hooks/useYardstik'

interface Props {
  flow: 'onboarding' | 'recollect'
  onOpenManualUpload?: () => void
}

const MVRYardstikComponent = ({ flow, onOpenManualUpload }: Props) => {
  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as MVROnboardingStep

  const { isYardstikLoading, startYardstik, YardstikView } = useYardstik(flow)

  const showYardstik = useCallback(() => {
    startYardstik(currentStep.id)
  }, [currentStep.id])

  return (
    <>
      <div css={tw`h-full w-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
          >
            <img src={MVRIcon} />
          </div>
          {flow === 'onboarding' && <OnboardingStepIndex type={OnboardingStepType.MVR} />}
          <Typography css={tw`mb-6 mt-2`} variant="h2">
            Motor Vehicle Record
          </Typography>
          <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
            To conduct your Motor Vehicle Record report we'll use our industry leading partner,
            Yardstick. Tap “Proceed with Yardstick” button below to begin.
          </Typography>
          {onOpenManualUpload ? (
            <div css={tw`rounded-2xl p-4 bg-white mt-6`}>
              <Typography css={tw`mb-1`} variant="h4">
                Already have Background Check & Motor Vehicle Report documents?
              </Typography>
              <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
                Please upload your documents to expedite the process.
              </Typography>
              <LoadingButton
                endIcon={<CircleRightMajor width={20} fill={colors.PRIMARY_PURPLE_500} />}
                css={tw`w-full bg-[#F2E5FF] hover:bg-[rgba(226, 208, 244, 1)]`}
                onClick={onOpenManualUpload}
              >
                Tap to upload documents
              </LoadingButton>
            </div>
          ) : null}
        </div>
        <LoadingButton
          css={tw`w-full`}
          loading={isYardstikLoading}
          variant="contained"
          onClick={showYardstik}
        >
          <span>Proceed with Yardstick</span>
        </LoadingButton>
      </div>
      <YardstikView />
    </>
  )
}

export const MVRYardstik = memo(MVRYardstikComponent)
