import { Formik } from 'formik'
import { useCallback, useRef, useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { createAsyncAction } from 'src/utils/reduxUtils'
import { GetPaidFormValues } from './GetPaidForm.interface'
import { GetPaidFormSchema } from './GetPaidForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material'
// import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { colors } from 'src/theme'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
// import { UploadedFile } from 'src/components/shared/UploadedFile'
import { FinancesMajor } from '@shopify/polaris-icons'
import { states } from 'src/constants/states'
// import { useNavigate } from 'react-router-dom'
// import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { OnboardingStepIndex } from '../OnboardingStepIndex'
import { OnboardingStepType } from 'src/constants/onboarding'

const initialValues: GetPaidFormValues = {
  ssn: '',
  ein: '',
  cardNumber: '',
  expirationDate: '',
  address: '',
  city: '',
  state: '',
  zipCode: '',
}

const IDENTIFICATION_METHOD_TYPE = {
  SSN: 'SSN',
  EIN: 'EIN',
}

export const GetPaidForm = () => {
  // const dispatch = useDispatch()

  const formikRef = useRef<any>()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [identificationType, setIdentificationType] = useState<string>(
    IDENTIFICATION_METHOD_TYPE.SSN,
  )

  const handleFormSubmit = async () => {
    setIsLoading(true)
    setError(null)

    try {
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChangeIdentificationType = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === IDENTIFICATION_METHOD_TYPE.SSN && formikRef.current) {
        formikRef.current.setFieldValue('ein', '')
      } else if (event.target.value === IDENTIFICATION_METHOD_TYPE.EIN && formikRef.current) {
        formikRef.current.setFieldValue('ssn', '')
      }

      setIdentificationType(event.target.value)
    },
    [],
  )

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={GetPaidFormSchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <FinancesMajor width={20} />
            </div>
            <OnboardingStepIndex type={OnboardingStepType.PaymentMethod} />
            <Typography css={tw`my-2`} variant="h2">
              Get paid!
            </Typography>
            <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
              Choose your preferred way to get paid!
            </Typography>
            <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
              Enter IDENTIFICATION method
            </Typography>
            <div css={tw`bg-white p-4 rounded-xl mt-2`}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={identificationType}
                  onChange={handleChangeIdentificationType}
                >
                  <FormControlLabel
                    value={IDENTIFICATION_METHOD_TYPE.SSN}
                    control={<Radio />}
                    label="SSN"
                  />
                  <FormControlLabel
                    value={IDENTIFICATION_METHOD_TYPE.EIN}
                    control={<Radio />}
                    label="EIN"
                  />
                </RadioGroup>
              </FormControl>
              <FormikTextField
                name="ssn"
                css={tw`w-full mb-4`}
                label="Social Security Number"
                placeholder="Enter SSN"
              />
            </div>
            <div css={tw`mt-6`}>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                Enter your debit card details
              </Typography>
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                <FormikTextField
                  name="cardNumber"
                  css={tw`w-full mb-4`}
                  label="Card number"
                  placeholder="0000 0000 0000 0000"
                />
                <FormikTextField
                  name="expirationDate"
                  css={tw`w-full`}
                  type="number"
                  label="Expiration date"
                  placeholder="MM / YY"
                />
              </div>
            </div>
            <div css={tw`mt-6`}>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                Enter your address
              </Typography>
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                <FormikTextField
                  name="address"
                  css={tw`w-full mb-4`}
                  label="Street address"
                  placeholder="Enter street address"
                />
                <FormikTextField
                  name="city"
                  css={tw`w-full mb-4`}
                  label="City"
                  placeholder="Enter city"
                />
                <div css={tw`w-full mb-4`}>
                  <FormikSelect
                    name="state"
                    label="State"
                    placeholder="Select state"
                    options={states}
                  />
                </div>
                <FormikTextField
                  name="zipCode"
                  css={tw`w-full mb-4`}
                  label="Zip code"
                  placeholder="Enter zip code"
                />
              </div>
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}
