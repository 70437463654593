import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { DriverLicensePage } from 'src/pages/onboarding/docs/DriverLicensePage'
import { VehicleInsurancePage } from 'src/pages/onboarding/docs/VehicleInsurancePage'
import { InsuranceDeclarationPage } from 'src/pages/onboarding/docs/InsuranceDeclarationPage'
import { VehicleRegistrationPage } from 'src/pages/onboarding/docs/VehicleRegistrationPage'
import { VaccinationPage } from 'src/pages/onboarding/docs/VaccinationPage'
import { BGCPage } from 'src/pages/onboarding/docs/BGCPage'
import { GetPaidPage } from 'src/pages/onboarding/docs/GetPaidPage'
import { InsuranceBeneficiaryPage } from 'src/pages/onboarding/docs/InsuranceBeneficiaryPage'
import { TakeTrainingPage } from 'src/pages/onboarding/docs/TakeTrainingPage'
import { getIsOnboardingDriverInitialized } from 'src/selectors/driver'
import { OnboardingSuccessPage } from '../../OnboardingSuccessPage'
import { LoadingPage } from '../../LoadingPage'
import {
  getIsOnboardingFailed,
  getCurrentOnboardingStep,
  getOnlyDelayedStepsLeft,
} from 'src/selectors/onboarding'
import { OnboardingStepType } from 'src/constants/onboarding'
import { SignContractPage } from './SignContractPage'
import { StitchPaymentPage } from './StitchPaymentPage'
import { QuestionnairePage } from './QuestionnairePage'
import { OnboardingFailedPage } from 'src/pages/OnboardingFailedPage'
import { MVRPage } from './MVRPage'
import { FormPage } from 'src/pages/onboarding/docs/FormPage'
import { EIN } from 'src/components/ein/EIN'

const stepsPageMap = {
  [OnboardingStepType.Questionnaire]: QuestionnairePage,
  [OnboardingStepType.DriverLicense]: DriverLicensePage,
  [OnboardingStepType.VehicleInsurance]: VehicleInsurancePage,
  [OnboardingStepType.InsuranceDeclaration]: InsuranceDeclarationPage,
  [OnboardingStepType.VehicleRegistration]: VehicleRegistrationPage,
  [OnboardingStepType.Vaccination]: VaccinationPage,
  [OnboardingStepType.BGC]: BGCPage,
  [OnboardingStepType.PaymentMethod]: GetPaidPage,
  [OnboardingStepType.StitchPayment]: StitchPaymentPage,
  [OnboardingStepType.InsuranceBeneficiary]: InsuranceBeneficiaryPage,
  [OnboardingStepType.SignContract]: SignContractPage,
  [OnboardingStepType.Training]: TakeTrainingPage,
  [OnboardingStepType.MVR]: MVRPage,
  [OnboardingStepType.EIN]: () => <EIN flow="onboarding" />,
  [OnboardingStepType.Form]: FormPage,
}

const OnboardingDocsPageComponent = () => {
  const currentStep = useSelector(getCurrentOnboardingStep)
  const onlyDelayedStepsLeft = useSelector(getOnlyDelayedStepsLeft)
  const isDriverInitialized = useSelector(getIsOnboardingDriverInitialized)
  const isOnboardingFailed = useSelector(getIsOnboardingFailed)

  if (!isDriverInitialized) {
    return <LoadingPage />
  }

  if (isOnboardingFailed) {
    return <OnboardingFailedPage />
  }

  if (!currentStep || onlyDelayedStepsLeft) {
    return <OnboardingSuccessPage />
  }

  const StepComponent = stepsPageMap[currentStep.type]

  if (StepComponent) {
    return <StepComponent />
  }

  return <OnboardingSuccessPage />
}

export const OnboardingDocsPage = memo(OnboardingDocsPageComponent)
