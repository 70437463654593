import { Button, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { RecollectionPublicWrapper } from 'src/components/layout/recollection/RecollectionPublicWrapper'
import { EnterCodeForm } from 'src/components/auth/enter-code-form/EnterCodeForm'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useMemo, useCallback } from 'react'
import { RECOLLECTION_ROUTES } from 'src/constants/routes'
import { ChevronLeftMinor } from '@shopify/polaris-icons'

export const RecollectionEnterCodePage = () => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()

  const phone = useMemo(() => location?.state?.phone || '****', [location])

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [])

  if (!params?.partnerName || !location?.state?.phone) {
    return <Navigate to={RECOLLECTION_ROUTES.NOT_FOUND_URL} />
  }

  return (
    <RecollectionPublicWrapper>
      <Button
        css={tw`bg-[#F2E5FF] h-[42px] w-[82px] hover:bg-[rgba(226, 208, 244, 1)]`}
        onClick={handleBack}
      >
        <div css={tw`items-center flex flex-row`}>
          <ChevronLeftMinor fill={colors.PRIMARY_PURPLE_500} width={19} />
          <Typography color={colors.PRIMARY_PURPLE_500} css={tw`[font-weight: 600]`}>
            Back
          </Typography>
        </div>
      </Button>
      <Typography variant="h2" css={tw`mt-4 mb-2`}>
        Enter the verification code we just sent.
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        {`Code has been sent to number ending ${phone.slice(-4)}`}
      </Typography>
      <EnterCodeForm platform="recollection" partnerName={params.partnerName} phone={phone} />
    </RecollectionPublicWrapper>
  )
}
