import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  RecollectionStitchStartFlow,
  RecollectionStitchCompletePayment,
  RecollectionFetchDriver,
} from 'src/constants/actionTypes'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface StitchStartFlowRequest {
  stepId: string
}

interface StitchStartFlowResponse {
  token: string
}

function* onStitchStartFlow(action: AsyncAction<StitchStartFlowRequest, StitchStartFlowResponse>) {
  try {
    const { stepId } = action.payload

    const { payload }: { payload: StitchStartFlowResponse } = yield call(
      callRecollectionSecureApi,
      Endpoints.StitchStartFlow,
      {
        apiService: ApiService.Paraworks,
        method: 'POST',
        body: {
          stepId,
        },
      },
    )

    yield put(RecollectionStitchStartFlow.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch stitch token'

    yield put(RecollectionStitchStartFlow.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface StitchCompletePaymentRequest {
  stepId: string
}

function* onStitchCompletePayment(action: AsyncAction<StitchCompletePaymentRequest>) {
  try {
    const { stepId } = action.payload

    yield call(callRecollectionSecureApi, Endpoints.RecollectionStitchCompletePayment, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        stepId,
      },
    })

    yield put(RecollectionStitchCompletePayment.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to complete payment'

    yield put(RecollectionStitchCompletePayment.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* recollectionStitchSaga() {
  yield takeEvery(RecollectionStitchStartFlow.type.REQUEST, onStitchStartFlow)
  yield takeEvery(RecollectionStitchCompletePayment.type.REQUEST, onStitchCompletePayment)
}
