import React, { memo } from 'react'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { VaccinationForm } from 'src/components/onboarding/vaccination-form/VaccinationForm'

const VaccinationPageComponent = () => {
  return (
    <OnboardingSecureWrapper>
      <VaccinationForm />
    </OnboardingSecureWrapper>
  )
}

export const VaccinationPage = memo(VaccinationPageComponent)
