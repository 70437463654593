export enum CollectDataFormItemType {
  TextBlock = 'textBlock',
  TextInput = 'textInput',
  Date = 'date',
  Number = 'number',
  Select = 'select',
  Radio = 'radio',
  Checkbox = 'checkbox',
  Video = 'video',
  File = 'file',
}
