import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { CollectDataFormItem as CollectDataFormItemEnum } from 'src/interfaces/collectData'
import { CollectDataFormItemType } from 'src/constants/collectData'
import { FormikTextField } from '../shared/form/formik/FormikTextField'
import { FormikSelect } from '../shared/form/formik/FormikSelect'
import { FormikFileInput } from '../shared/form/formik/FormikFileInput'
import { FormikRadioGroup } from '../shared/form/formik/FormikRadioGroup'
import { FormikCheckboxSelect } from '../shared/form/formik/FormikCheckboxSelect'
import './CollectDataFormItemStyles.css'

interface Props {
  collectDataFormItem: CollectDataFormItemEnum
}

const DOB_MASK = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]

export const CollectDataFormItem = ({ collectDataFormItem }: Props) => {
  if (collectDataFormItem.type === CollectDataFormItemType.TextBlock) {
    return (
      <div
        className="collect-data-custom-html"
        dangerouslySetInnerHTML={{
          __html: collectDataFormItem.text,
        }}
      />
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.TextInput) {
    return (
      <div>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikTextField
          css={tw`w-full mb-8`}
          name={collectDataFormItem.id}
          placeholder={collectDataFormItem.title}
        />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Date) {
    return (
      <div>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikTextField
          css={tw`w-full mb-8`}
          name={collectDataFormItem.id}
          mask={DOB_MASK}
          placeholder="MM/DD/YYYY"
        />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Number) {
    return (
      <div>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikTextField
          css={tw`w-full mb-8`}
          name={collectDataFormItem.id}
          placeholder={collectDataFormItem.title}
        />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Select) {
    return (
      <div css={tw`mb-8`}>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikSelect
          css={tw`w-full`}
          name={collectDataFormItem.id}
          options={collectDataFormItem.options.map((item) => ({
            label: item.title,
            value: item.id,
          }))}
        />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Video) {
    return (
      <div css={tw`mb-8`}>
        <Typography css={tw`mb-4`} variant="h4">
          {collectDataFormItem.title}
        </Typography>
        <iframe css={tw`w-full rounded-xl`} frameBorder="0" src={collectDataFormItem.url}></iframe>
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.File) {
    return (
      <div css={tw`mb-8`}>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikFileInput name={collectDataFormItem.id} />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Radio) {
    return (
      <div css={tw`mb-8 w-full`}>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikRadioGroup
          name={collectDataFormItem.id}
          options={collectDataFormItem.options.map((item) => ({
            value: item.id,
            label: item.title,
          }))}
        />
      </div>
    )
  }

  if (collectDataFormItem.type === CollectDataFormItemType.Checkbox) {
    return (
      <div css={tw`mb-8 w-full`}>
        <Typography css={tw`mb-4`} variant="h4">
          {`${collectDataFormItem.title}${collectDataFormItem.required ? '*' : ''}`}
        </Typography>
        <FormikCheckboxSelect
          name={collectDataFormItem.id}
          options={collectDataFormItem.options.map((item) => ({
            value: item.id,
            label: item.title,
          }))}
        />
      </div>
    )
  }

  return null
}
