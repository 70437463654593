import React, { memo } from 'react'
import { VehicleInsuranceForm } from 'src/components/onboarding/vehicle-insurance-form/VehicleInsuranceForm'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'

const VehicleInsurancePageComponent = () => {
  return (
    <OnboardingSecureWrapper>
      <VehicleInsuranceForm />
    </OnboardingSecureWrapper>
  )
}

export const VehicleInsurancePage = memo(VehicleInsurancePageComponent)
