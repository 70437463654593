import * as Yup from 'yup'

export const EINFormSchema = Yup.object().shape({
  file: Yup.string().required('Ein letter is required'),
  ein: Yup.number()
    .typeError('Must be a number')
    .required('EIN is required')
    .min(9, 'EIN is invalid'),
  businessName: Yup.string().required('Business name is required'),
  streetAddress: Yup.string().required('Street address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zipCode: Yup.number().typeError('Must be a number').required('Zip code is required'),
})
