import React, { memo } from 'react'
import { EINForm } from 'src/components/ein/EINForm'
import { SecureWrapper } from '../layout/SecureWrapper'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const EINComponent = ({ flow }: Props) => {
  return (
    <SecureWrapper flow={flow}>
      <EINForm flow={flow} />
    </SecureWrapper>
  )
}

export const EIN = memo(EINComponent)
