import React, { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import SuccessImage from 'src/assets/images/success.png'
import { OnboardingPublicWrapper } from 'src/components/layout/onboarding/OnboardingPublicWrapper'

const OnboardingSuccessPageComponent = () => {
  return (
    <OnboardingPublicWrapper>
      <div css={tw`h-full flex flex-col justify-between px-4`}>
        <img src={SuccessImage} css={tw`h-[220px] w-[280px] self-center`} />
        <div>
          <Typography css={tw`my-4 text-center`} variant="h2">
            Your document submission was successful!
          </Typography>
          <Typography css={tw`text-center`} color={colors.GRAY_DARK_COOL}>
            We will keep you informed about your status through SMS notifications.
          </Typography>
        </div>
      </div>
    </OnboardingPublicWrapper>
  )
}

export const OnboardingSuccessPage = memo(OnboardingSuccessPageComponent)
