import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, AsyncAction } from 'src/utils/reduxUtils'
import { OnboardingFetchDriver, OnboardingSetDriver } from 'src/constants/actionTypes'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { OnboardingDriver } from 'src/interfaces/onboarding'
import * as Sentry from '@sentry/react'

function* onFetchDriver(action: AsyncAction<OnboardingDriver>) {
  try {
    const { payload }: { payload: OnboardingDriver } = yield call(
      callOnboardingSecureApi,
      Endpoints.OnboardingFetchDriver,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(OnboardingFetchDriver.success())
    yield put(OnboardingSetDriver.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch driver'

    yield put(OnboardingFetchDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverSuccess(action: Action<OnboardingDriver | null>) {
  const driver = action.payload

  if (driver) {
    Sentry.setUser({ id: driver.id })
  } else {
    Sentry.setUser(null)
  }
}

export function* onboardingDriverSaga() {
  yield takeEvery(OnboardingFetchDriver.type.REQUEST, onFetchDriver)
  yield takeEvery(OnboardingSetDriver.type.SUCCESS, onSetDriverSuccess)
}
