import { states } from './states'

export enum DocusignFieldTabLabel {
  fullName = 'fullName',
  email = 'email',
  address = 'address',
  city = 'city',
  state = 'state',
  zipcode = 'zipcode',
  cityStateZipcode = 'cityStateZipcode',
  phoneNumber = 'phoneNumber',
  phoneNumberHome = 'phoneNumberHome',
  ssn = 'ssn',
  tin = 'tin',
  fein = 'fein',
  dob = 'dob',
  beneficiary = 'beneficiary',
  beneficiaryRelationship = 'beneficiaryRelationship',
  insuranceCarrier = 'insuranceCarrier',
  otherInsurancePlan = 'otherInsurancePlan',
  eSignatureAck = 'eSignatureAck',
  businessName = 'businessName',
  ein = 'ein',
  vehicleYear = 'vehicleYear',
  vehicleMake = 'vehicleMake',
  vehicleVIN = 'vehicleVIN',
  vehicleLicensePlate = 'vehicleLicensePlate',
  vehicleYear2 = 'vehicleYear2',
  vehicleMake2 = 'vehicleMake2',
  vehicleVIN2 = 'vehicleVIN2',
  vehicleLicensePlate2 = 'vehicleLicensePlate2',
}

export const DocusignFieldNames = {
  [DocusignFieldTabLabel.phoneNumber]: 'Phone number',
  [DocusignFieldTabLabel.phoneNumberHome]: 'Home Phone number',
  [DocusignFieldTabLabel.email]: 'Email address',
  [DocusignFieldTabLabel.fullName]: 'Full name',
  [DocusignFieldTabLabel.address]: 'Address',
  [DocusignFieldTabLabel.city]: 'City',
  [DocusignFieldTabLabel.state]: 'State',
  [DocusignFieldTabLabel.zipcode]: 'ZIP code',
  [DocusignFieldTabLabel.cityStateZipcode]: 'City, state, and ZIP code',
  [DocusignFieldTabLabel.ssn]: 'Social security number',
  [DocusignFieldTabLabel.tin]: 'Taxpayer ID number',
  [DocusignFieldTabLabel.fein]: 'Federal employer ID number',
  [DocusignFieldTabLabel.dob]: 'Your Date of Birth',
  [DocusignFieldTabLabel.beneficiary]: 'Beneficiary',
  [DocusignFieldTabLabel.beneficiaryRelationship]: 'Relationship to Beneficiary',
  [DocusignFieldTabLabel.insuranceCarrier]: 'If Yes, name of insurance carrier',
  [DocusignFieldTabLabel.otherInsurancePlan]:
    'Are you covered under any other medical and/or disability insurance plan?',
  [DocusignFieldTabLabel.eSignatureAck]: 'Electronic signature acknowledgement',
  [DocusignFieldTabLabel.businessName]: 'Business entity name',
  [DocusignFieldTabLabel.ein]: 'Employer Identification Number (EIN)',
  [DocusignFieldTabLabel.vehicleYear]: 'Vehicle Year',
  [DocusignFieldTabLabel.vehicleMake]: 'Vehicle Make',
  [DocusignFieldTabLabel.vehicleVIN]: 'Vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate]: 'Vehicle License Plate',
  [DocusignFieldTabLabel.vehicleYear2]: 'Additional Vehicle Year',
  [DocusignFieldTabLabel.vehicleMake2]: 'Additional Vehicle Make',
  [DocusignFieldTabLabel.vehicleVIN2]: 'Additional Vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate2]: 'Additional Vehicle License Plate',
}

export const DocusignFieldPlaceholders = {
  [DocusignFieldTabLabel.phoneNumber]: 'Enter phone number',
  [DocusignFieldTabLabel.phoneNumberHome]: 'Enter home phone number',
  [DocusignFieldTabLabel.email]: 'Enter email address',
  [DocusignFieldTabLabel.fullName]: 'Enter full name',
  [DocusignFieldTabLabel.address]: 'Enter address (number, street, and apt. or suite no.)',
  [DocusignFieldTabLabel.city]: 'Enter city',
  [DocusignFieldTabLabel.state]: 'Enter state',
  [DocusignFieldTabLabel.zipcode]: 'Enter ZIP code',
  [DocusignFieldTabLabel.cityStateZipcode]: 'Enter city, state, and ZIP code',
  [DocusignFieldTabLabel.ssn]: 'Enter social security number',
  [DocusignFieldTabLabel.tin]: 'Enter taxpayer ID number',
  [DocusignFieldTabLabel.fein]: 'Enter federal employer ID number',
  [DocusignFieldTabLabel.dob]: 'Enter your date of birth',
  [DocusignFieldTabLabel.beneficiary]: 'Enter beneficiary',
  [DocusignFieldTabLabel.beneficiaryRelationship]: 'Enter relationship to beneficiary',
  [DocusignFieldTabLabel.insuranceCarrier]: 'Enter insurance carrier',
  [DocusignFieldTabLabel.otherInsurancePlan]: '',
  [DocusignFieldTabLabel.eSignatureAck]: '',
  [DocusignFieldTabLabel.businessName]: 'Enter business entity name',
  [DocusignFieldTabLabel.ein]: 'Enter employer identification number',
  [DocusignFieldTabLabel.vehicleYear]: 'Enter vehicle year',
  [DocusignFieldTabLabel.vehicleMake]: 'Enter vehicle make',
  [DocusignFieldTabLabel.vehicleVIN]: 'Enter vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate]: 'Enter vehicle license plate',
  [DocusignFieldTabLabel.vehicleYear2]: 'Enter additional vehicle year',
  [DocusignFieldTabLabel.vehicleMake2]: 'Enter additional vehicle make',
  [DocusignFieldTabLabel.vehicleVIN2]: 'Enter additional vehicle VIN',
  [DocusignFieldTabLabel.vehicleLicensePlate2]: 'Enter additional vehicle license plate',
}

export const DocusignFieldSelectOptions: any = {
  [DocusignFieldTabLabel.state]: states,
}
