import { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { colors } from 'src/theme'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { TextBlockMajor } from '@shopify/polaris-icons'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { CommonModal } from 'src/components/shared/CommonModal'
import { RecollectionUploadVehicleRegistration } from 'src/constants/actionTypes'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

const ACCESS_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const VehicleRegistrationPage = () => {
  const dispatch = useDispatch()

  const currentStep = useSelector(getCurrentRecollectionStep)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [vehicleRegistrationFile, setVehicleRegistrationFile] = useState<File | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        RecollectionUploadVehicleRegistration.request({
          file: vehicleRegistrationFile,
          stepId: currentStep?.id,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadVehicleRegistration = useCallback(async (file: File) => {
    setVehicleRegistrationFile(file)
  }, [])

  const handleRemoveFile = useCallback(() => {
    setVehicleRegistrationFile(null)
  }, [])

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <RecollectionSecureWrapper>
      <div css={tw`h-full w-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
          >
            <TextBlockMajor width={20} />
          </div>
          <Typography css={tw`my-2`} variant="h2">
            Vehicle registration
          </Typography>
          <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
            Submit your vehicle registration by uploading a photo of it.
          </Typography>
          <div css={tw`mb-4`}>
            <InfoContainer onClick={openModal}>
              Tap to get more information about vehicle registration
            </InfoContainer>
          </div>
          <div css={tw`mt-6`}>
            <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
              Upload vehicle registration
            </Typography>
            <div css={tw`bg-white p-4 rounded-xl mt-2`}>
              {vehicleRegistrationFile ? (
                <UploadedFile file={vehicleRegistrationFile} onRemove={handleRemoveFile} />
              ) : (
                <FileUploadInput
                  // 10 MB
                  fileSizeLimit={10000000}
                  acceptTypes={ACCESS_TYPES}
                  onSelect={handleUploadVehicleRegistration}
                />
              )}
            </div>
          </div>
        </div>
        <FormErrorMessage css={tw`mb-4`} error={error} />
        <LoadingButton
          css={tw`w-full mt-6`}
          variant="contained"
          loading={isLoading}
          disabled={!vehicleRegistrationFile}
          onClick={handleSubmit}
        >
          <span>Submit and continue</span>
        </LoadingButton>
        <CommonModal
          isOpen={showModal}
          title="Vehicle registration"
          text="Follow the provided examples."
          image={require('../../../assets/images/vehicle-registration-example.png')}
          onClose={closeModal}
        />
      </div>
    </RecollectionSecureWrapper>
  )
}
