import { useCallback, useEffect, useRef } from 'react'

export const usePolling = (handler: (stopPolling: () => void) => void, delay: number) => {
  const interval = useRef<any>(null)

  const stopPolling = useCallback(() => {
    if (interval.current) {
      clearInterval(interval.current)
    }
  }, [])

  const startPolling = useCallback(() => {
    stopPolling()
    interval.current = setInterval(() => {
      handler(stopPolling)
    }, delay)
  }, [handler])

  useEffect(() => {
    stopPolling()
    startPolling()

    return () => {
      stopPolling()
    }
  }, [handler])

  return {
    startPolling,
    stopPolling,
  }
}
