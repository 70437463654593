import { CollectDataFormItemType } from 'src/constants/collectData'
import { CollectDataFormInputItem } from 'src/interfaces/collectData'

export const getFormUploadValue = (formItem: CollectDataFormInputItem, answer: any) => {
  if (!answer || (formItem.type === CollectDataFormItemType.Checkbox && !answer?.length)) {
    return null
  }

  if (formItem.type === CollectDataFormItemType.Number) {
    return +answer
  }

  if (
    formItem.type === CollectDataFormItemType.Radio ||
    formItem.type === CollectDataFormItemType.Select
  ) {
    return {
      id: answer,
      title: formItem.options.find((item) => item.id === answer)?.title,
    }
  }

  if (formItem.type === CollectDataFormItemType.Checkbox) {
    return answer.map((answerItem: any) => ({
      id: answerItem,
      title: formItem.options.find((item) => item.id === answerItem)?.title,
    }))
  }

  return answer
}
