import { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { Checkbox } from './Checkbox'

interface Props {
  title: string
  selected?: boolean
  onClick(): void
}

const CheckboxButtonComponent = ({ title, selected, onClick }: Props) => (
  <div
    css={tw`flex flex-1 items-center justify-center bg-[#F6F6F6] rounded-lg hover:bg-[#F2E5FF]`}
    onClick={onClick}
  >
    <Checkbox selected={selected} />
    <Typography css={tw`ml-2`} variant="h4">
      {title}
    </Typography>
  </div>
)

export const CheckboxButton = memo(CheckboxButtonComponent)
