import React from 'react'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { CollectDataForm } from 'src/components/collect-data-form/CollectDataForm'

export const FormPage = () => {
  return (
    <OnboardingSecureWrapper>
      <CollectDataForm flow="onboarding" />
    </OnboardingSecureWrapper>
  )
}
