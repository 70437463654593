import dayjs from 'dayjs'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'validateExpirationDate', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 4) {
      return createError({ path, message: errorMessage })
    }

    const date = dayjs(value, { format: 'YYYY' })
    const today = dayjs()

    return today.isAfter(date) || createError({ path, message: errorMessage })
  })
})

export const GetPaidFormSchema = Yup.object().shape({
  expirationDate: Yup.string()
    .required('Expiration date is required')
    // @ts-ignore
    .validateExpirationDate('Expiration date is invalid'),
})
