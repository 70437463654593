import * as Yup from 'yup'
import { parsePhoneNumber } from 'libphonenumber-js'

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 10) {
      return createError({ path, message: errorMessage })
    }

    const parsedPhoneNumber = parsePhoneNumber(value, 'US')

    return (
      !!parsedPhoneNumber?.formatInternational() || createError({ path, message: errorMessage })
    )
  })
})

export const SignInSchema = Yup.object().shape({
  // @ts-ignore
  phone: Yup.string().required('Phone number is required').validatePhone('Phone number is invalid'),
})
