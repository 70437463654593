import { memo, useEffect } from 'react'
import { useAsyncDispatchWithErrorToast } from './hooks/useAsyncDispatchWithErrorToast'
import { RecollectionFetchDriver } from './constants/actionTypes'

export const RecollectionSecureHooksContainer = memo(() => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  useEffect(() => {
    asyncDispatchWithErrorToast(RecollectionFetchDriver.request())
  }, [])

  return <div />
})
