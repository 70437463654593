import { RecollectionLogout, RecollectionSetDriver } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { RecollectionDriverState } from './types'

const getInitialState = (): RecollectionDriverState => ({
  recollectionDriver: null,
  isRecollectionDriverInitialized: false,
})

export const recollectionDriverReducer = (
  state: RecollectionDriverState = getInitialState(),
  action: Action<any>,
): RecollectionDriverState => {
  switch (action.type) {
    case RecollectionSetDriver.type.SUCCESS:
      return {
        ...state,
        isRecollectionDriverInitialized: true,
        recollectionDriver: action.payload,
      }
    case RecollectionLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
