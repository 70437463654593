import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  RecollectionFetchDriver,
  RecollectionFetchPersonaInquiry,
  RecollectionSetPersonaClientCompletedInquiry,
  RecollectionSetPersonaSessionToken,
} from 'src/constants/actionTypes'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { PersonaInquiry } from 'src/interfaces/persona'
import { PersonaInquiryType } from 'src/constants/persona'

interface FetchPersonaInquiryRequest {
  stepId: string
  flow: 'onboarding' | 'recollection'
  inquiryType?: PersonaInquiryType
}

function* onFetchPersonaInquiry(action: AsyncAction<FetchPersonaInquiryRequest, PersonaInquiry>) {
  try {
    const { payload }: { payload: PersonaInquiry } = yield call(
      callRecollectionSecureApi,
      Endpoints.FetchPersonaInquiry,
      {
        apiService: ApiService.Paraworks,
        query: {
          stepId: action.payload.stepId,
          source: action.payload.flow,
          inquiryType: action.payload.inquiryType,
        },
      },
    )

    yield put(RecollectionFetchPersonaInquiry.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch inquiry'

    yield put(RecollectionFetchPersonaInquiry.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SetPersonaSessionTokenRequest {
  sessionToken: string
  stepId: string
}

function* onSetPersonaSessionToken(action: AsyncAction<SetPersonaSessionTokenRequest>) {
  try {
    const { sessionToken, stepId } = action.payload

    yield call(callRecollectionSecureApi, Endpoints.SetPersonaSessionToken, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        sessionToken,
      },
      query: {
        stepId,
      },
    })

    yield put(RecollectionSetPersonaSessionToken.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to set session token'

    yield put(RecollectionSetPersonaSessionToken.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SetPersonaClientCompletedInquiryRequest {
  stepId: string
}

function* onSetPersonaClientCompletedInquiry(
  action: AsyncAction<SetPersonaClientCompletedInquiryRequest>,
) {
  try {
    yield call(callRecollectionSecureApi, Endpoints.SetPersonaClientCompletedInquiry, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      query: {
        stepId: action.payload.stepId,
      },
    })

    yield put(RecollectionSetPersonaClientCompletedInquiry.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to set client completed inquiry'

    yield put(RecollectionSetPersonaClientCompletedInquiry.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* recollectionPersonaSaga() {
  yield takeEvery(RecollectionFetchPersonaInquiry.type.REQUEST, onFetchPersonaInquiry)
  yield takeEvery(RecollectionSetPersonaSessionToken.type.REQUEST, onSetPersonaSessionToken)
  yield takeEvery(
    RecollectionSetPersonaClientCompletedInquiry.type.REQUEST,
    onSetPersonaClientCompletedInquiry,
  )
}
