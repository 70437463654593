import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import React, { ReactNode } from 'react'

export type PartnerNameType = 'dropoff' // add more if needed

export const partnerAgreementContentMap: Record<PartnerNameType, ReactNode> = {
  dropoff: (
    <>
      <Typography css={tw`mb-2`} variant="h2">
        Settlement Deductions Agreement
      </Typography>
      <Typography css={tw`mb-6`} color={colors.SHADES_GREY_400}>
        By signing this contract, you are agreeing to its terms and GigSafe's Terms of Service and
        Privacy Policy. Further, you agree to all payment settlement deductions and fees relating to
        1099 services including, but not limited to:
      </Typography>
      <Typography css={tw`font-bold`} color={colors.SHADES_GREY_400}>
        Safety & Administrative Fee: $4 per week.
      </Typography>
      <Typography css={tw`mb-6`} color={colors.SHADES_GREY_400}>
        Paid weekly, whenever a settlement is paid. This includes access to the GigSafe platform and
        all of its contractor platform features including payments and continuous MVR monitoring.
      </Typography>
      <Typography css={tw`font-bold`} color={colors.SHADES_GREY_400}>
        Occupational Accidental Insurance: $0.036 per mile.
      </Typography>
      <Typography css={tw`mb-6`} color={colors.SHADES_GREY_400}>
        If applicable, mandated coverage for all deliveries or similar services provided to GigSafe
        customers by you, the contractor, and accounted for in the GigSafe system.
      </Typography>
      <Typography css={tw`font-bold`} color={colors.SHADES_GREY_400}>
        Commercial Auto Insurance: Variable.
      </Typography>
      <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
        If applicable, any other plans or policies opted into by you or mandated by GigSafe, its
        Customers, or required by Federal or State laws. You can opt out of this deduction if you
        provide proof of commercial insurance that meets customers requirements.
      </Typography>
    </>
  ),
}
