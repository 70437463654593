export enum InsuranceBeneficiaryRelation {
  Spouse = 'spouse',
  DomesticPartner = 'domesticPartner',
  Child = 'child',
  Parent = 'parent',
  Other = 'other',
}

export const insuranceBeneficiaryRelationFormTypes = [
  {
    label: 'Spouse',
    value: InsuranceBeneficiaryRelation.Spouse,
  },
  { label: 'Domestic Partner', value: InsuranceBeneficiaryRelation.DomesticPartner },
  {
    label: 'Child',
    value: InsuranceBeneficiaryRelation.Child,
  },
  {
    label: 'Parent',
    value: InsuranceBeneficiaryRelation.Parent,
  },
  {
    label: 'Other',
    value: InsuranceBeneficiaryRelation.Other,
  },
]
