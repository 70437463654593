import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { FileUploadInput } from 'src/components/shared/FileUploadInput'
import { colors } from 'src/theme'
import { UploadedFile } from 'src/components/shared/UploadedFile'
import { TextBlockMajor } from '@shopify/polaris-icons'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { RecollectionUploadTraining } from 'src/constants/actionTypes'
import { createAsyncAction } from 'src/utils/reduxUtils'
import './TraningStyles.css'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { TrainingOnboardingStep } from 'src/interfaces/onboarding'

const ACCESS_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const TakeTrainingPage = () => {
  const dispatch = useDispatch()

  const trainingStep = useSelector(getCurrentRecollectionStep) as TrainingOnboardingStep

  const [filesMap, setFilesMap] = useState<Map<string, File>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleSubmit = async () => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        RecollectionUploadTraining.request({
          files: Array.from(filesMap, ([fieldName, file]) => ({ fieldName, file })),
          stepId: trainingStep.id,
        }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadFile = useCallback(
    (fieldName: string) => (file: File) => {
      setFilesMap((prev) => new Map(prev.set(fieldName, file)))
    },
    [],
  )

  const handleRemoveFile = useCallback((fieldName: string) => {
    setFilesMap((prev) => {
      prev.delete(fieldName)

      return new Map(prev)
    })
  }, [])

  if (!trainingStep) {
    return <div />
  }

  return (
    <RecollectionSecureWrapper>
      <div css={tw`h-full w-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
          >
            <TextBlockMajor width={20} />
          </div>
          <Typography css={tw`my-2`} variant="h2">
            {trainingStep.metadata.title}
          </Typography>
          <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
            {trainingStep.metadata.subtitle}
          </Typography>

          {trainingStep.metadata.partnerTrainingCardTemplates?.map((item) => (
            <div css={tw`mt-6`} key={item.title}>
              <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                {item.title}
              </Typography>
              <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                <div
                  className="training-custom-html"
                  dangerouslySetInnerHTML={{
                    __html: item.content,
                  }}
                />
              </div>
            </div>
          ))}
          {trainingStep.metadata.actions?.map((item) =>
            item.type === 'file' ? (
              <div css={tw`mt-6`} key={item.title}>
                <Typography css={tw`uppercase`} variant="overline" color={colors.GRAY_COOL_GRAY}>
                  {item.title}
                </Typography>
                <div css={tw`bg-white p-4 rounded-xl mt-2`}>
                  {filesMap.has(item.fieldName) ? (
                    <UploadedFile
                      file={filesMap.get(item.fieldName) as File}
                      onRemove={() => {
                        handleRemoveFile(item.fieldName)
                      }}
                    />
                  ) : (
                    <FileUploadInput
                      // 10 MB
                      fileSizeLimit={10000000}
                      acceptTypes={ACCESS_TYPES}
                      onSelect={handleUploadFile(item.fieldName)}
                    />
                  )}
                </div>
              </div>
            ) : null,
          )}
          <Typography css={tw`text-center mt-4`} color={colors.SHADES_GREY_400}>
            If you have any issues with the program, please reach out to{' '}
            <Typography
              display="inline"
              color={colors.PRIMARY_PURPLE_500}
              fontWeight={600}
              css={tw`underline [text-transform: none]`}
            >
              help@gigsafe.com
            </Typography>
          </Typography>
        </div>
        <FormErrorMessage css={tw`mb-4`} error={error} />
        <LoadingButton
          css={tw`w-full mt-6`}
          variant="contained"
          loading={isLoading}
          disabled={
            trainingStep?.metadata?.actions?.some((item) => item.type === 'file') && !filesMap.size
          }
          onClick={handleSubmit}
        >
          <span>Submit and continue</span>
        </LoadingButton>
      </div>
    </RecollectionSecureWrapper>
  )
}
