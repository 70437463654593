import React, { ReactNode } from 'react'
import { OnboardingSecureWrapper } from './onboarding/OnboardingSecureWrapper'
import { RecollectionSecureWrapper } from './recollection/RecollectionSecureWrapper'

interface Props {
  flow: 'onboarding' | 'recollect'
  children?: ReactNode
  onBack?: () => void
  onSkip?: () => void
}

export const SecureWrapper = ({ flow, children, onBack, onSkip }: Props) => {
  return flow === 'onboarding' ? (
    <OnboardingSecureWrapper children={children} onBack={onBack} onSkip={onSkip} />
  ) : (
    <RecollectionSecureWrapper children={children} />
  )
}
