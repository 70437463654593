import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { OnboardingFetchYardstikReport } from 'src/constants/actionTypes'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { YardstikReport } from 'src/interfaces/yardstik'

interface FetchYardstikReportRequest {
  stepId: string
}

function* onFetchYardstikReport(action: AsyncAction<FetchYardstikReportRequest, YardstikReport>) {
  try {
    const { payload }: { payload: YardstikReport } = yield call(
      callOnboardingSecureApi,
      Endpoints.FetchYardstikReport,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        query: {
          stepId: action.payload.stepId,
          flow: 'onboarding',
        },
      },
    )

    yield put(OnboardingFetchYardstikReport.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch background check report'

    yield put(OnboardingFetchYardstikReport.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingYardstikSaga() {
  yield takeEvery(OnboardingFetchYardstikReport.type.REQUEST, onFetchYardstikReport)
}
