import React from 'react'
import { useFormikContext } from 'formik'
import { BasicTextFieldProps } from '../BasicTextField'
import { UploadedFile } from '../../UploadedFile'
import { FileUploadInput } from '../../FileUploadInput'
import { FormControl, FormHelperText } from '@mui/material'

const ACCESS_TYPES = ['image/jpeg', 'image/png', 'application/pdf']

export const FormikFileInput = <T extends unknown>({ name }: BasicTextFieldProps<T>) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } = useFormikContext<T>()

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <div>
      <FormControl fullWidth error={!!error}>
        {values[name] ? (
          <UploadedFile
            file={values[name] as any}
            onRemove={() => {
              setFieldTouched(name as string, false)
              setFieldValue(name as string, null)
            }}
          />
        ) : (
          <FileUploadInput
            // 10 MB
            fileSizeLimit={10000000}
            acceptTypes={ACCESS_TYPES}
            onSelect={(file) => {
              setFieldValue(name as string, file)
            }}
          />
        )}
        {isTouched && error ? <FormHelperText>{error}</FormHelperText> : null}
      </FormControl>
    </div>
  )
}
