import { ReactNode, useCallback } from 'react'
import tw from 'twin.macro'
import { Button, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { ChevronLeftMinor, ChevronRightMinor } from '@shopify/polaris-icons'
import background from 'src/assets/images/background.svg'

interface Props {
  children?: ReactNode
  onBack?: () => void
  onSkip?: () => void
}

export const OnboardingSecureWrapper = ({ children, onBack, onSkip }: Props) => {
  const navigate = useNavigate()

  const handleBack = useCallback(() => {
    navigate(ONBOARDING_ROUTES.HOME_URL)
  }, [])

  return (
    <div css={tw`h-full w-full flex flex-col`}>
      <div css={tw`flex flex-1 bg-[#300258] items-center justify-center flex-col lg:py-16`}>
        <img src={background} css={tw`fixed top-0 bottom-0 z-0 h-full`} />
        <div
          css={tw`bg-white sm:max-w-[390px] mx-4 sm:min-h-[80vh] w-full flex flex-1 sm:flex-none flex-col sm:rounded-2xl overflow-hidden z-10 sm:my-4`}
        >
          <div
            css={tw`bg-white h-16 p-4 [box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.07)] sticky top-0 flex items-center justify-between`}
          >
            <Button
              variant="contained"
              color="secondary"
              onClick={onBack || handleBack}
              css={tw`bg-[#EDEDED] hover:bg-gray-200 h-[42px]`}
            >
              <div css={tw`items-center flex flex-row`}>
                <ChevronLeftMinor width={20} />
                <Typography css={tw`text-black [font-weight: 600]`}>Back</Typography>
              </div>
            </Button>
            {onSkip && (
              <Button
                variant="contained"
                color="secondary"
                onClick={onSkip}
                css={tw`bg-[#F2E5FF] hover:bg-[rgba(226, 208, 244, 1)] h-[42px]`}
              >
                <div css={tw`items-center flex flex-row`}>
                  <Typography css={tw`text-[#7F00FF] [font-weight: 600]`}>Skip step</Typography>
                  <ChevronRightMinor fill="#7F00FF" width={20} />
                </div>
              </Button>
            )}
          </div>
          <div css={tw`flex flex-1 bg-[#F6F6F6] items-center justify-center flex-col p-4 `}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
