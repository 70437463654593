import { Typography, SvgIcon } from '@mui/material'
import React, { memo } from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { TimelineAttachmentMajor } from '@shopify/polaris-icons'
import { Cancel } from '@mui/icons-material'

interface Props {
  file: File
  onRemove: () => void
}

const UploadedFileComponent = ({ file, onRemove }: Props) => {
  return (
    <div
      css={[
        tw`relative border border-gray-300 rounded-lg border-solid p-5 pr-1 flex flex-1 flex-row justify-between items-center transition duration-300 ease-linear mb-8`,
      ]}
    >
      <SvgIcon css={tw`w-5 h-5`}>
        <TimelineAttachmentMajor color={colors.GRAY_BLACK} />
      </SvgIcon>
      <div css={tw`flex flex-1 flex-col ml-4 mr-2`}>
        <Typography css={tw`mb-1`}>{`${file.name.slice(0, 22)}${
          file.name.length > 22 ? '...' : ''
        }`}</Typography>
        <Typography variant="body2" color={colors.SUCCESS}>
          Completed
        </Typography>
      </div>
      <div css={tw`flex items-center cursor-pointer w-10 h-10 justify-center`} onClick={onRemove}>
        <Cancel color="disabled" />
      </div>
    </div>
  )
}

export const UploadedFile = memo(UploadedFileComponent)
