import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingFetchDriver,
  OnboardingFetchPersonaInquiry,
  OnboardingSetPersonaClientCompletedInquiry,
  OnboardingSetPersonaSessionToken,
} from 'src/constants/actionTypes'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { PersonaInquiry } from 'src/interfaces/persona'
import { PersonaInquiryType } from 'src/constants/persona'

interface FetchPersonaInquiryRequest {
  stepId: string
  flow: 'onboarding' | 'recollection'
  inquiryType?: PersonaInquiryType
}

function* onFetchPersonaInquiry(action: AsyncAction<FetchPersonaInquiryRequest, PersonaInquiry>) {
  try {
    const { payload }: { payload: PersonaInquiry } = yield call(
      callOnboardingSecureApi,
      Endpoints.FetchPersonaInquiry,
      {
        apiService: ApiService.Paraworks,
        query: {
          stepId: action.payload.stepId,
          source: action.payload.flow,
          inquiryType: action.payload.inquiryType,
        },
      },
    )

    yield put(OnboardingFetchPersonaInquiry.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch inquiry'

    yield put(OnboardingFetchPersonaInquiry.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SetPersonaSessionTokenRequest {
  sessionToken: string
  stepId: string
}

function* onSetPersonaSessionToken(action: AsyncAction<SetPersonaSessionTokenRequest>) {
  try {
    const { sessionToken, stepId } = action.payload

    yield call(callOnboardingSecureApi, Endpoints.SetPersonaSessionToken, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        sessionToken,
      },
      query: {
        stepId,
      },
    })

    yield put(OnboardingSetPersonaSessionToken.success())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to set session token'

    yield put(OnboardingSetPersonaSessionToken.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface SetPersonaClientCompletedInquiryRequest {
  stepId: string
}

function* onSetPersonaClientCompletedInquiry(
  action: AsyncAction<SetPersonaClientCompletedInquiryRequest>,
) {
  try {
    yield call(callOnboardingSecureApi, Endpoints.SetPersonaClientCompletedInquiry, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      query: {
        stepId: action.payload.stepId,
      },
    })

    yield put(OnboardingSetPersonaClientCompletedInquiry.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to set client completed inquiry'

    yield put(OnboardingSetPersonaClientCompletedInquiry.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingPersonaSaga() {
  yield takeEvery(OnboardingFetchPersonaInquiry.type.REQUEST, onFetchPersonaInquiry)
  yield takeEvery(OnboardingSetPersonaSessionToken.type.REQUEST, onSetPersonaSessionToken)
  yield takeEvery(
    OnboardingSetPersonaClientCompletedInquiry.type.REQUEST,
    onSetPersonaClientCompletedInquiry,
  )
}
