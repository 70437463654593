import { Typography } from '@mui/material'
import React, { memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { OnboardingUploadMVR, RecollectionUploadMVR } from 'src/constants/actionTypes'
import MVRIcon from 'src/assets/icons/bgc.svg'
import { MVROnboardingStep } from 'src/interfaces/onboarding'
import { OnboardingStepType } from 'src/constants/onboarding'
import { OnboardingStepIndex } from 'src/components/onboarding/OnboardingStepIndex'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { Formik } from 'formik'
import { MVRManualUploadFormSchema } from './MVRManualUploadForm.validator'
import { FormikFileInput } from '../shared/form/formik/FormikFileInput'
import { MVRManualUploadFormValues } from './MVRManualUploadForm.interface'
import { FormErrorMessage } from '../shared/form/FormErrorMessage'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

interface Props {
  flow: 'onboarding' | 'recollect'
  isYardstikEnabled?: boolean
}

const initialValues: MVRManualUploadFormValues = {
  mvrFile: undefined,
}

const MVRManualUploadComponent = ({ flow, isYardstikEnabled }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as MVROnboardingStep

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: MVRManualUploadFormValues) => {
    setIsLoading(true)
    setError(null)

    const requestBody = {
      stepId: currentStep.id,
      file: values.mvrFile,
    }

    try {
      if (flow === 'onboarding') {
        await createAsyncAction(dispatch, OnboardingUploadMVR.request(requestBody))
      } else if (flow === 'recollect') {
        await createAsyncAction(dispatch, RecollectionUploadMVR.request(requestBody))
      }
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={MVRManualUploadFormSchema}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <img src={MVRIcon} />
            </div>
            {flow === 'onboarding' && <OnboardingStepIndex type={OnboardingStepType.MVR} />}
            <Typography css={tw`mb-6 mt-2`} variant="h2">
              {isYardstikEnabled ? 'Upload required document' : 'Motor Vehicle Record'}
            </Typography>
            <Typography color={colors.GRAY_DARK_COOL}>
              {isYardstikEnabled
                ? 'Once you submit the document, you will automatically advance to the next step.'
                : 'Please upload required document. Once you submit the document, you will automatically advance to the next step.'}
            </Typography>
            <div css={tw`mt-6 bg-white p-4 rounded-2xl mb-8`}>
              <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
                Check must be less than 60 days old.
              </Typography>
              <FormikFileInput name="mvrFile" />
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full`}
            variant="contained"
            onClick={() => {
              handleSubmit()
            }}
            loading={isLoading}
          >
            <span>Submit document</span>
          </LoadingButton>
        </div>
      )}
    </Formik>
  )
}

export const MVRManualUpload = memo(MVRManualUploadComponent)
