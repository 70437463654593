import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { FetchPartnerPublicInfo } from 'src/constants/actionTypes'
import { ApiService, callApi } from './api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { PartnerPublicInfo } from 'src/interfaces/partner'

interface FetchPartnerPublicInfoParams {
  partnerName: string
}

function* onFetchPartnerPublicInfo(
  action: AsyncAction<FetchPartnerPublicInfoParams, PartnerPublicInfo>,
) {
  try {
    const { payload }: { payload: PartnerPublicInfo } = yield call(
      callApi,
      Endpoints.FetchPartnerInfo,
      {
        method: 'GET',
        apiService: ApiService.Paraworks,
        params: [
          {
            field: ':partnerName',
            value: action.payload.partnerName,
          },
        ],
      },
    )

    yield put(FetchPartnerPublicInfo.success(payload))

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch partner signing url'

    yield put(FetchPartnerPublicInfo.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* partnerSaga() {
  yield takeEvery(FetchPartnerPublicInfo.type.REQUEST, onFetchPartnerPublicInfo)
}
