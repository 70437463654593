class AuthService {
  private STORAGE_RECOLLECTION_AUTH_TOKEN_KEY = 'recollectionAuthToken'
  private STORAGE_ONBOARDING_AUTH_TOKEN_KEY = 'onboardingAuthToken'

  public setStoredRecollectionAuthToken(authToken: string | null) {
    if (!authToken) {
      localStorage.removeItem(this.STORAGE_RECOLLECTION_AUTH_TOKEN_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_RECOLLECTION_AUTH_TOKEN_KEY, authToken)
  }

  public getStoredRecollectionAuthToken(): string | null {
    const authToken = localStorage.getItem(this.STORAGE_RECOLLECTION_AUTH_TOKEN_KEY)

    return authToken
  }

  public setStoredOnboardingAuthToken(authToken: string | null) {
    if (!authToken) {
      localStorage.removeItem(this.STORAGE_ONBOARDING_AUTH_TOKEN_KEY)
      return
    }

    localStorage.setItem(this.STORAGE_ONBOARDING_AUTH_TOKEN_KEY, authToken)
  }

  public getStoredOnboardingAuthToken(): string | null {
    const authToken = localStorage.getItem(this.STORAGE_ONBOARDING_AUTH_TOKEN_KEY)

    return authToken
  }
}

export const authService = new AuthService()
