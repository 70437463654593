import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  RecollectionUploadBasicInfo,
  RecollectionFetchDriver,
  RecollectionUploadInsurance,
  RecollectionUploadVaccination,
  RecollectionUploadInsuranceBeneficiary,
  RecollectionUploadTraining,
  RecollectionUploadInsuranceDeclaration,
  RecollectionUploadVehicleRegistration,
  RecollectionUploadForm,
  RecollectionUploadBGC,
  RecollectionUploadMVR,
  RecollectionUploadEIN,
} from 'src/constants/actionTypes'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { InsuranceBeneficiaryRelation } from 'src/constants/insuranceBeneficiary'
import { UploadFormResponse } from 'src/interfaces/collectData'

interface UploadBasicInfoRequest {
  firstName: string
  lastName: string
  dob: string // 01/01/1999
  stepId: string
}

function* onUploadBasicInfo(action: AsyncAction<UploadBasicInfoRequest>) {
  const { firstName, lastName, dob, stepId } = action.payload

  try {
    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadBasicInfo, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        firstName,
        lastName,
        dob,
        stepId,
      },
    })

    yield put(RecollectionUploadBasicInfo.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload basic info'

    yield put(RecollectionUploadBasicInfo.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadInsuranceRequest {
  file: File
  type?: string
  year?: string | number
  carMake?: string
  carModel?: string
  doorsCount?: string | number
  stepId: string
}

function* onUploadInsurance(action: AsyncAction<UploadInsuranceRequest>) {
  const { file, type, year, carMake, carModel, doorsCount, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)

    formData.append('stepId', stepId)

    if (type) {
      formData.append('type', type)
    }

    if (year && carMake && carModel && doorsCount) {
      formData.append('year', String(year))
      formData.append('carMake', carMake)
      formData.append('carModel', carModel)
      formData.append('doorsCount', String(doorsCount))
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadInsurance, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadInsurance.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload insurance'

    yield put(RecollectionUploadInsurance.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}
interface UploadInsuranceDeclarationRequest {
  file: File
  stepId: string
}

function* onUploadInsuranceDeclaration(action: AsyncAction<UploadInsuranceDeclarationRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('stepId', stepId)

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadInsuranceDeclaration, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadInsuranceDeclaration.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload insurance declaration'

    yield put(RecollectionUploadInsuranceDeclaration.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadVehicleRegistrationRequest {
  file: File
  stepId: string
}

function* onUploadVehicleRegistration(action: AsyncAction<UploadVehicleRegistrationRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('file', file)
    formData.append('stepId', stepId)

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadVehicleRegistration, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadVehicleRegistration.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload vehicle insurance'

    yield put(RecollectionUploadVehicleRegistration.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadVaccinationRequest {
  brand?: string
  date?: string // 2024/12/10
  isVaccinated: boolean
  stepId: string
}

function* onUploadVaccination(action: AsyncAction<UploadVaccinationRequest>) {
  const { brand, date, isVaccinated, stepId } = action.payload

  try {
    const body: UploadVaccinationRequest = {
      isVaccinated,
      stepId,
    }

    if (isVaccinated && brand && date) {
      body.brand = brand
      body.date = date
    }

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadVaccination, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body,
    })

    yield put(RecollectionUploadVaccination.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload vaccination'

    yield put(RecollectionUploadVaccination.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadInsuranceBeneficiaryRequest {
  firstName: string
  lastName: string
  relation: InsuranceBeneficiaryRelation
  stepId: string
}

function* onUploadInsuranceBeneficiary(action: AsyncAction<UploadInsuranceBeneficiaryRequest>) {
  const { firstName, lastName, relation, stepId } = action.payload

  try {
    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadInsuranceBeneficiary, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: {
        firstName,
        lastName,
        relation,
        stepId,
      },
    })

    yield put(RecollectionUploadInsuranceBeneficiary.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload insurance beneficiary'

    yield put(RecollectionUploadInsuranceBeneficiary.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadTrainingRequest {
  files: Array<{ fieldName: string; file: File }>
  stepId: string
}

function* onUploadTraining(action: AsyncAction<UploadTrainingRequest>) {
  const { files, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    files.forEach((item) => {
      formData.append('files', item.file, item.fieldName)
    })

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadTraining, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadTraining.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload certificates'

    yield put(RecollectionUploadTraining.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadFormRequest {
  data: Array<{ id: string; value: any }>
  files: Array<{ id: string; value: File }>
  stepId: string
}

function* onUploadForm(action: AsyncAction<UploadFormRequest>) {
  const { data, stepId, files } = action.payload

  try {
    const formData = new FormData()

    if (files.length) {
      files.forEach((item) => {
        formData.append('files', item.value, item.id)
      })
    }

    formData.append(
      'body',
      JSON.stringify({
        data,
        stepId,
      }),
    )

    const { payload }: { payload: UploadFormResponse } = yield call(
      callRecollectionSecureApi,
      Endpoints.RecollectionUploadForm,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        body: formData,
        resetContentType: true,
        returnResponse: true,
      },
    )

    yield put(RecollectionUploadForm.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload form'

    yield put(RecollectionUploadForm.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadMVRRequest {
  file?: File
  stepId: string
}

function* onUploadMVR(action: AsyncAction<UploadMVRRequest>) {
  const { file, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    if (file) {
      formData.append('file', file)
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadMVR, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadMVR.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload mvr'

    yield put(RecollectionUploadMVR.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadBGCRequest {
  files: Array<{ file: File; name: string }>
  stepId: string
}

function* onUploadBGC(action: AsyncAction<UploadBGCRequest>) {
  const { files, stepId } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)

    if (files.length) {
      files.forEach((item) => {
        formData.append('files', item.file, item.name)
      })
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadBGC, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadBGC.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload bgc'

    yield put(RecollectionUploadBGC.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface UploadEINRequest {
  stepId: string
  file?: File
  ein?: string | number
  businessName?: string
  streetAddress?: string
  city?: string
  state?: string
  zipCode?: string | number
  isBusinessEntityFlow?: boolean
}

function* onUploadEIN(action: AsyncAction<UploadEINRequest>) {
  const {
    file,
    ein,
    zipCode,
    stepId,
    businessName,
    streetAddress,
    city,
    state,
    isBusinessEntityFlow,
  } = action.payload

  try {
    const formData = new FormData()

    formData.append('stepId', stepId)
    formData.append('isBusinessEntityFlow', JSON.stringify(isBusinessEntityFlow))

    if (file) {
      formData.append('file', file)
    }
    if (ein) {
      formData.append('ein', String(ein))
    }
    if (zipCode) {
      formData.append('zipCode', String(zipCode))
    }
    if (businessName) {
      formData.append('businessName', businessName)
    }
    if (streetAddress) {
      formData.append('streetAddress', streetAddress)
    }
    if (city) {
      formData.append('city', city)
    }
    if (state) {
      formData.append('state', state)
    }

    formData.forEach((value, key) => console.log(value, key))

    yield call(callRecollectionSecureApi, Endpoints.RecollectionUploadEIN, {
      method: 'POST',
      apiService: ApiService.Paraworks,
      body: formData,
      resetContentType: true,
      returnResponse: true,
    })

    yield put(RecollectionUploadEIN.success())
    yield put(RecollectionFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to upload ein'

    yield put(RecollectionUploadEIN.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* recollectionDocumentsSaga() {
  yield takeEvery(RecollectionUploadBasicInfo.type.REQUEST, onUploadBasicInfo)
  yield takeEvery(RecollectionUploadInsurance.type.REQUEST, onUploadInsurance)
  yield takeEvery(RecollectionUploadInsuranceDeclaration.type.REQUEST, onUploadInsuranceDeclaration)
  yield takeEvery(RecollectionUploadVehicleRegistration.type.REQUEST, onUploadVehicleRegistration)
  yield takeEvery(RecollectionUploadVaccination.type.REQUEST, onUploadVaccination)
  yield takeEvery(RecollectionUploadInsuranceBeneficiary.type.REQUEST, onUploadInsuranceBeneficiary)
  yield takeEvery(RecollectionUploadTraining.type.REQUEST, onUploadTraining)
  yield takeEvery(RecollectionUploadForm.type.REQUEST, onUploadForm)
  yield takeEvery(RecollectionUploadBGC.type.REQUEST, onUploadBGC)
  yield takeEvery(RecollectionUploadMVR.type.REQUEST, onUploadMVR)
  yield takeEvery(RecollectionUploadEIN.type.REQUEST, onUploadEIN)
}
