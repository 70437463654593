import { RecollectionLogout, RecollectionSetNewDocsUploaded } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { DocumentsState } from './types'

const initialState = {
  isNewDocsUploaded: false,
}

export const documentsReducer = (
  state: DocumentsState = initialState,
  action: Action<any>,
): DocumentsState => {
  switch (action.type) {
    case RecollectionSetNewDocsUploaded.type.SUCCESS:
      return {
        ...state,
        isNewDocsUploaded: action.payload,
      }
    case RecollectionLogout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
