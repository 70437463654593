import { OnboardingStep } from 'src/interfaces/onboarding'
import { OnboardingStatus, OnboardingStepType } from 'src/constants/onboarding'

export const backendSortSteps = (steps: OnboardingStep[]): OnboardingStep[] => {
  return steps.sort((a, b) => a.order - b.order)
}

export const clientSortSteps = (steps: OnboardingStep[]): OnboardingStep[] => {
  return steps.sort((a, b) => {
    if (a.type === OnboardingStepType.Form) {
      return -1
    } else if (b.type === OnboardingStepType.Form) {
      return 1
    }

    if (a.type === OnboardingStepType.Questionnaire) {
      return -1
    } else if (b.type === OnboardingStepType.Questionnaire) {
      return 1
    }

    if (a.type === OnboardingStepType.DriverLicense) {
      return -1
    } else if (b.type === OnboardingStepType.DriverLicense) {
      return 1
    }

    if (a.type === OnboardingStepType.VehicleInsurance) {
      return -1
    } else if (b.type === OnboardingStepType.VehicleInsurance) {
      return 1
    }

    if (a.type === OnboardingStepType.InsuranceDeclaration) {
      return -1
    } else if (b.type === OnboardingStepType.InsuranceDeclaration) {
      return 1
    }

    if (a.type === OnboardingStepType.VehicleRegistration) {
      return -1
    } else if (b.type === OnboardingStepType.VehicleRegistration) {
      return 1
    }

    if (a.type === OnboardingStepType.Vaccination) {
      return -1
    } else if (b.type === OnboardingStepType.Vaccination) {
      return 1
    }

    if (a.type === OnboardingStepType.BGC) {
      return -1
    } else if (b.type === OnboardingStepType.BGC) {
      return 1
    }

    if (a.type === OnboardingStepType.PaymentMethod) {
      return -1
    } else if (b.type === OnboardingStepType.PaymentMethod) {
      return 1
    }

    if (a.type === OnboardingStepType.StitchPayment) {
      return -1
    } else if (b.type === OnboardingStepType.StitchPayment) {
      return 1
    }

    if (a.type === OnboardingStepType.InsuranceBeneficiary) {
      return -1
    } else if (b.type === OnboardingStepType.InsuranceBeneficiary) {
      return 1
    }

    if (a.type === OnboardingStepType.SignContract) {
      return -1
    } else if (b.type === OnboardingStepType.SignContract) {
      return 1
    }

    if (a.type === OnboardingStepType.MVR) {
      return -1
    } else if (b.type === OnboardingStepType.MVR) {
      return 1
    }

    if (a.type === OnboardingStepType.EIN) {
      return -1
    } else if (b.type === OnboardingStepType.EIN) {
      return 1
    }

    return -1
  })
}

export const sortByDisabled = (steps: OnboardingStep[]): OnboardingStep[] => {
  return steps.sort((a, b) => {
    if (a.disabled && !b.disabled) {
      return 1
    } else if (b.disabled && !a.disabled) {
      return -1
    }

    return 0
  })
}

export const sortByCreated = (steps: OnboardingStep[]): OnboardingStep[] => {
  return steps.sort((a, b) => {
    if (a.status === OnboardingStatus.Created && b.status !== OnboardingStatus.Created) {
      return 1
    } else if (b.status === OnboardingStatus.Created && a.status !== OnboardingStatus.Created) {
      return -1
    }

    return 0
  })
}
