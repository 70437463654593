import React from 'react'
import { CollectDataForm } from 'src/components/collect-data-form/CollectDataForm'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'

export const FormPage = () => {
  return (
    <RecollectionSecureWrapper>
      <CollectDataForm flow="recollect" />
    </RecollectionSecureWrapper>
  )
}
