import * as Yup from 'yup'
import { parsePhoneNumber } from 'libphonenumber-js'
import { DocusignFieldTabLabel } from 'src/constants/docusign'
import { DocusignSigningField } from 'src/interfaces/docusign'

Yup.addMethod(Yup.string, 'validatePhone', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 10) {
      return createError({ path, message: errorMessage })
    }

    const parsedPhoneNumber = parsePhoneNumber(value, 'US')

    return (
      !!parsedPhoneNumber?.formatInternational() || createError({ path, message: errorMessage })
    )
  })
})

const validationMap = {
  [DocusignFieldTabLabel.phoneNumber]: Yup.string()
    // @ts-ignore
    .validatePhone('Phone number is invalid'),
  [DocusignFieldTabLabel.phoneNumberHome]: Yup.string()
    // @ts-ignore
    .validatePhone('Home phone number is invalid'),
  [DocusignFieldTabLabel.email]: Yup.string().email('Email is invalid'),
  [DocusignFieldTabLabel.fullName]: Yup.string(),
  [DocusignFieldTabLabel.address]: Yup.string(),
  [DocusignFieldTabLabel.city]: Yup.string(),
  [DocusignFieldTabLabel.state]: Yup.string(),
  [DocusignFieldTabLabel.zipcode]: Yup.string().length(5, 'ZIP code is invalid'),
  [DocusignFieldTabLabel.cityStateZipcode]: Yup.string(),
  [DocusignFieldTabLabel.ssn]: Yup.string().length(11, 'Social security number is invalid'),
  [DocusignFieldTabLabel.tin]: Yup.string().length(11, 'Taxpayer ID number is invalid'),
  [DocusignFieldTabLabel.fein]: Yup.string().length(10, 'Federal employer ID number is invalid'),
  [DocusignFieldTabLabel.dob]: Yup.string().length(10, 'Date of birth is invalid'),
  [DocusignFieldTabLabel.beneficiary]: Yup.string(),
  [DocusignFieldTabLabel.beneficiaryRelationship]: Yup.string(),
  [DocusignFieldTabLabel.insuranceCarrier]: Yup.string(),
  [DocusignFieldTabLabel.otherInsurancePlan]: Yup.string().required(
    'Please chose other insurance plan option',
  ),
  [DocusignFieldTabLabel.eSignatureAck]: Yup.boolean().oneOf(
    [true],
    'Electronic signature acknowledgement is required',
  ),
  [DocusignFieldTabLabel.businessName]: Yup.string(),
  [DocusignFieldTabLabel.ein]: Yup.string().length(
    10,
    'Employer Identification Number (EIN) is invalid',
  ),
  [DocusignFieldTabLabel.vehicleYear]: Yup.string().length(4, 'Vehicle Year is invalid'),
  [DocusignFieldTabLabel.vehicleMake]: Yup.string(),
  [DocusignFieldTabLabel.vehicleVIN]: Yup.string().length(17, 'Vehicle VIN is invalid'),
  [DocusignFieldTabLabel.vehicleLicensePlate]: Yup.string(),
  [DocusignFieldTabLabel.vehicleYear2]: Yup.string().length(4, 'Vehicle Year is invalid'),
  [DocusignFieldTabLabel.vehicleMake2]: Yup.string(),
  [DocusignFieldTabLabel.vehicleVIN2]: Yup.string().length(17, 'Vehicle VIN is invalid'),
  [DocusignFieldTabLabel.vehicleLicensePlate2]: Yup.string(),
}

const requiredMap = {
  [DocusignFieldTabLabel.phoneNumber]: 'Phone number is required',
  [DocusignFieldTabLabel.phoneNumberHome]: 'Home phone number is required',
  [DocusignFieldTabLabel.email]: 'Email address is required',
  [DocusignFieldTabLabel.fullName]: 'Full name is required',
  [DocusignFieldTabLabel.address]: 'Address is required',
  [DocusignFieldTabLabel.city]: 'City is required',
  [DocusignFieldTabLabel.state]: 'State is required',
  [DocusignFieldTabLabel.zipcode]: 'ZIP code is required',
  [DocusignFieldTabLabel.cityStateZipcode]: 'City, state, and ZIP code are required',
  [DocusignFieldTabLabel.ssn]: 'Social security number is required',
  [DocusignFieldTabLabel.tin]: 'Taxpayer ID number is required',
  [DocusignFieldTabLabel.fein]: 'Federal employer ID number is required',
  [DocusignFieldTabLabel.dob]: 'Date of birth is required',
  [DocusignFieldTabLabel.beneficiary]: 'Beneficiary is required',
  [DocusignFieldTabLabel.beneficiaryRelationship]: 'Relationship to beneficiary is required',
  [DocusignFieldTabLabel.insuranceCarrier]: 'Insurance Carrier is required',
  [DocusignFieldTabLabel.otherInsurancePlan]: 'Please chose other insurance plan option',
  [DocusignFieldTabLabel.eSignatureAck]: 'Please acknowledge eSignature',
  [DocusignFieldTabLabel.businessName]: 'Business entity name is required',
  [DocusignFieldTabLabel.ein]: 'Employer Identification Number (EIN) is required',
  [DocusignFieldTabLabel.vehicleYear]: 'Vehicle Year is required',
  [DocusignFieldTabLabel.vehicleMake]: 'Vehicle Make is required',
  [DocusignFieldTabLabel.vehicleVIN]: 'Vehicle VIN is required',
  [DocusignFieldTabLabel.vehicleLicensePlate]: 'Vehicle License Plate is required',
  [DocusignFieldTabLabel.vehicleYear2]: 'Additional Vehicle Year is required',
  [DocusignFieldTabLabel.vehicleMake2]: 'Additional Vehicle Make is required',
  [DocusignFieldTabLabel.vehicleVIN2]: 'Additional Vehicle VIN is required',
  [DocusignFieldTabLabel.vehicleLicensePlate2]: 'Additional Vehicle License Plate is required',
}

export const getValidationSchema = (fields: Array<DocusignSigningField>) => {
  const schemaObject: any = {}

  fields.forEach((item) => {
    if (!validationMap[item.tabLabel]) {
      return
    }

    schemaObject[item.tabLabel] = validationMap[item.tabLabel]

    if (item.required) {
      schemaObject[item.tabLabel] = schemaObject[item.tabLabel].required(
        requiredMap[item.tabLabel] || 'Required',
      )
    }
  })

  return Yup.object().shape(schemaObject)
}
