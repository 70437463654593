import React, { memo } from 'react'
import { InsuranceForm } from 'src/components/recollection/insurance-form/InsuranceForm'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'

const InsurancePageComponent = () => {
  return (
    <RecollectionSecureWrapper>
      <InsuranceForm />
    </RecollectionSecureWrapper>
  )
}

export const InsurancePage = memo(InsurancePageComponent)
