import { Scope } from '@sentry/types'
import * as Sentry from '@sentry/react'

export const captureSentryError = (err: any, options?: { message?: string; data?: any }) => {
  Sentry.captureException(err, (scope: Scope) => {
    if (options?.message) {
      scope.addBreadcrumb({
        type: 'error',
        category: 'error',
        level: 'error',
        message: options.message,
      })
    }

    if (options?.data) {
      scope.setContext('extra-data', options.data)
    }

    return scope
  })
}
