import React, { memo } from 'react'
import { Settings } from '../settings/Settings'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const GetHelpComponent = ({ flow }: Props) => {
  return <Settings flow={flow} />
}

export const GetHelp = memo(GetHelpComponent)
