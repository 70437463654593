import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingStitchStartFlow,
  OnboardingStitchCompletePayment,
  OnboardingFetchDriver,
} from 'src/constants/actionTypes'
import { ApiService, callOnboardingSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'

interface StitchStartFlowRequest {
  stepId: string
}

interface StitchStartFlowResponse {
  token: string
}

function* onStitchStartFlow(action: AsyncAction<StitchStartFlowRequest, StitchStartFlowResponse>) {
  try {
    const { stepId } = action.payload

    const { payload }: { payload: StitchStartFlowResponse } = yield call(
      callOnboardingSecureApi,
      Endpoints.StitchStartFlow,
      {
        apiService: ApiService.Paraworks,
        method: 'POST',
        body: {
          stepId,
        },
      },
    )

    yield put(OnboardingStitchStartFlow.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch stitch token'

    yield put(OnboardingStitchStartFlow.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

interface StitchCompletePaymentRequest {
  stepId: string
}

function* onStitchCompletePayment(action: AsyncAction<StitchCompletePaymentRequest>) {
  const { stepId } = action.payload

  try {
    yield call(callOnboardingSecureApi, Endpoints.StitchCompletePayment, {
      apiService: ApiService.Paraworks,
      method: 'POST',
      body: {
        stepId,
      },
    })

    yield put(OnboardingStitchCompletePayment.success())
    yield put(OnboardingFetchDriver.request())

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to complete payment'

    yield put(OnboardingStitchCompletePayment.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* onboardingStitchSaga() {
  yield takeEvery(OnboardingStitchStartFlow.type.REQUEST, onStitchStartFlow)
  yield takeEvery(OnboardingStitchCompletePayment.type.REQUEST, onStitchCompletePayment)
}
