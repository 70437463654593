import { combineReducers } from 'redux'
import { onboardingAuthReducer } from './onboardingAuth'
import { onboardingDriverReducer } from './onboardingDriver'
import { recollectionAuthReducer } from './recollectionAuth'
import { recollectionDriverReducer } from './recollectionDriver'
import { documentsReducer } from './documents'
import { partnerReducer } from './partner'

export const rootReducer = combineReducers({
  recollectionAuth: recollectionAuthReducer,
  recollectionDriver: recollectionDriverReducer,
  onboardingAuth: onboardingAuthReducer,
  onboardingDriver: onboardingDriverReducer,
  documents: documentsReducer,
  partner: partnerReducer,
})
