import { fork } from 'redux-saga/effects'

import { recollectionAuthSaga } from './recollection/auth'
import { recollectionDriverSaga } from './recollection/driver'
import { recollectionDocumentsSaga } from './recollection/documents'
import { recollectionPersonaSaga } from './recollection/persona'
import { recollectionYardstikSaga } from './recollection/yardstik'
import { recollectionDocusignSaga } from './recollection/docusign'
import { recollectionStitchSaga } from './recollection/stitch'

import { onboardingAuthSaga } from './onboarding/auth'
import { onboardingDriverSaga } from './onboarding/driver'
import { onboardingDocumentsSaga } from './onboarding/documents'
import { onboardingPersonaSaga } from './onboarding/persona'
import { onboardingYardstikSaga } from './onboarding/yardstik'
import { onboardingDocusignSaga } from './onboarding/docusign'
import { onboardingStitchSaga } from './onboarding/stitch'

import { partnerSaga } from './partner'

export function* rootSaga() {
  yield fork(recollectionAuthSaga)
  yield fork(onboardingAuthSaga)
  yield fork(recollectionDriverSaga)
  yield fork(onboardingDocumentsSaga)
  yield fork(onboardingDriverSaga)
  yield fork(recollectionDocumentsSaga)
  yield fork(recollectionPersonaSaga)
  yield fork(recollectionYardstikSaga)
  yield fork(recollectionStitchSaga)
  yield fork(onboardingPersonaSaga)
  yield fork(onboardingYardstikSaga)
  yield fork(recollectionDocusignSaga)
  yield fork(onboardingDocusignSaga)
  yield fork(onboardingStitchSaga)
  yield fork(partnerSaga)
}
