import { call, put, takeEvery } from 'redux-saga/effects'
import { Action, AsyncAction } from 'src/utils/reduxUtils'
import { RecollectionFetchDriver, RecollectionSetDriver } from 'src/constants/actionTypes'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { OnboardingDriver } from 'src/interfaces/onboarding'
import * as Sentry from '@sentry/react'

function* onFetchDriver(action: AsyncAction<void>) {
  try {
    const { payload }: { payload: OnboardingDriver } = yield call(
      callRecollectionSecureApi,
      Endpoints.RecollectionFetchDriver,
      {
        apiService: ApiService.Paraworks,
      },
    )

    yield put(RecollectionFetchDriver.success())
    yield put(RecollectionSetDriver.success(payload))

    if (action.next) {
      action.next(null)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch driver'

    yield put(RecollectionFetchDriver.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

function* onSetDriverSuccess(action: Action<OnboardingDriver | null>) {
  const driver = action.payload

  if (driver) {
    Sentry.setUser({ id: driver.id })
  } else {
    Sentry.setUser(null)
  }
}

export function* recollectionDriverSaga() {
  yield takeEvery(RecollectionFetchDriver.type.REQUEST, onFetchDriver)
  yield takeEvery(RecollectionSetDriver.type.SUCCESS, onSetDriverSuccess)
}
