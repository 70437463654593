import { Formik } from 'formik'
import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { InsuranceBeneficiaryFormValues } from './InsuranceBeneficiaryForm.interface'
import { InsuranceBeneficiarySchema } from './InsuranceBeneficiaryForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { ShipmentMajor } from '@shopify/polaris-icons'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { OnboardingUploadInsuranceBeneficiary } from 'src/constants/actionTypes'
import { CommonModal } from 'src/components/shared/CommonModal'
import { OnboardingStepIndex } from '../OnboardingStepIndex'
import { OnboardingStepType } from 'src/constants/onboarding'
import { getOnboardingDriver } from 'src/selectors/driver'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import {
  InsuranceBeneficiaryRelation,
  insuranceBeneficiaryRelationFormTypes,
} from 'src/constants/insuranceBeneficiary'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'

const initialValues: InsuranceBeneficiaryFormValues = {
  firstName: '',
  lastName: '',
  relation: InsuranceBeneficiaryRelation.Spouse,
}

export const InsuranceBeneficiaryForm = () => {
  const dispatch = useDispatch()

  const driver = useSelector(getOnboardingDriver)
  const currentStep = useSelector(getCurrentOnboardingStep)

  const formikRef = useRef<any>()

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: InsuranceBeneficiaryFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        OnboardingUploadInsuranceBeneficiary.request({ ...values, stepId: currentStep?.id }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={InsuranceBeneficiarySchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mt-2 mb-4`}
            >
              <ShipmentMajor width={20} />
            </div>
            <OnboardingStepIndex type={OnboardingStepType.InsuranceBeneficiary} />
            <Typography css={tw`my-2`} variant="h2">
              Insurance beneficiary
            </Typography>
            <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
              {`The GigSafe Occupational Accident Policy provides comprehensive coverage to you for
              occupational accidents and injuries sustained while on ${driver?.partner.displayName} jobs. The coverage
              includes accidental death, dismemberment, medical expenses, and temporary/continuous
              total disability benefits. The policy is paid as a deduction from your settlements.`}
            </Typography>
            <div css={tw`mb-4`}>
              <InfoContainer onClick={openModal}>
                Tap to discover additional details about your potential beneficiary
              </InfoContainer>
            </div>
            <div css={tw`bg-white p-4 rounded-xl`}>
              <InfoContainer color="orange" className={tw`mb-4`}>
                Please note: you cannot use your own name.
              </InfoContainer>
              <FormikTextField
                name="firstName"
                css={tw`w-full mb-4`}
                label="First name"
                placeholder="Enter your beneficiary's first name"
              />
              <FormikTextField
                name="lastName"
                css={tw`w-full mb-4`}
                label="Last name"
                placeholder="Enter your beneficiary's last name"
              />
              <div css={tw`w-full mb-4`}>
                <FormikSelect
                  name="relation"
                  label="Relation to You"
                  placeholder="Select relation type"
                  options={insuranceBeneficiaryRelationFormTypes}
                />
              </div>
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>

          <CommonModal
            isOpen={showModal}
            title="Who is your beneficiary?"
            text="A beneficiary is an individual or entity designated to receive assets, benefits, or insurance proceeds in the event of you or the policy holder's death. A beneficiary can not be the named insured (e.g. someone other than yourself)."
            subtext="Most people list the next of kin as their beneficiary. For example, you might list your spouse, child, or parent as the beneficiary to receive benefits in the case of your death."
            onClose={closeModal}
          />
        </div>
      )}
    </Formik>
  )
}
