import { Typography } from '@mui/material'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getIsRecollectionDriverInitialized } from 'src/selectors/driver'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import Persona from 'persona'
import { PersonaInquiry } from 'src/interfaces/persona'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  RecollectionFetchPersonaInquiry,
  RecollectionSetNewDocsUploaded,
  RecollectionSetPersonaClientCompletedInquiry,
  RecollectionSetPersonaSessionToken,
} from 'src/constants/actionTypes'
import { InquiryError } from 'persona/dist/lib/interfaces'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { captureSentryError } from 'src/utils/sentry'
import { showToast } from 'src/utils/toast'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import DriverIcon from 'src/assets/icons/driver-front.svg'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { PersonaInquiryType } from 'src/constants/persona'

const DriverLicensePageComponent = () => {
  const dispatch = useDispatch()

  const isDriverInitialized = useSelector(getIsRecollectionDriverInitialized)
  const currentStep = useSelector(getCurrentRecollectionStep)

  const [personaInquiry, setPersonaInquiry] = useState<PersonaInquiry | null>(null)

  useEffect(() => {
    if (!currentStep) {
      return
    }
    const handler = async () => {
      try {
        const inquiry: PersonaInquiry | undefined = await createAsyncAction(
          dispatch,
          RecollectionFetchPersonaInquiry.request({
            stepId: currentStep?.id,
            flow: 'recollection',
            inquiryType: PersonaInquiryType.DriverLicense,
          }),
        )

        if (inquiry) {
          setPersonaInquiry(inquiry)
        }
      } catch (err) {
        showToast("Failed to load driver's license page, please try again later", {
          variant: 'error',
        })
      }
    }

    handler()
  }, [currentStep])

  const handlePersonaCancel = useCallback(
    async (sessionToken: string | undefined) => {
      if (!sessionToken) {
        return
      }

      try {
        setPersonaInquiry((state) => {
          if (!state) {
            return null
          }

          return {
            ...state,
            sessionToken,
          }
        })

        await createAsyncAction(
          dispatch,
          RecollectionSetPersonaSessionToken.request({ sessionToken, stepId: currentStep?.id }),
        )
      } catch (err) {
        captureSentryError(err, {
          message: '[handlePersonaCancel]',
        })
      }
    },
    [currentStep],
  )

  const handlePersonaError = useCallback((error: InquiryError) => {
    captureSentryError(error, {
      message: '[handlePersonaError]',
    })
  }, [])

  const handlePersonaComplete = useCallback(async () => {
    // TODO: navigate to the next step

    try {
      await createAsyncAction(
        dispatch,
        RecollectionSetPersonaClientCompletedInquiry.request({ stepId: currentStep?.id }),
      )
      dispatch(RecollectionSetNewDocsUploaded.success(true))
    } catch (err) {
      showToast("Failed to complete driver's license step, please try again later", {
        variant: 'error',
      })
    }
  }, [currentStep])

  const personaClient = useMemo(() => {
    if (!personaInquiry) {
      return null
    }

    return new Persona.Client({
      // templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
      environmentId: process.env.REACT_APP_PERSONA_ENV_ID,
      inquiryId: personaInquiry.id,
      sessionToken: personaInquiry.sessionToken,
      onCancel: ({ sessionToken }) => handlePersonaCancel(sessionToken),
      onError: handlePersonaError,
      onComplete: handlePersonaComplete,
    })
  }, [personaInquiry])

  useEffect(() => {
    return () => {
      if (!personaClient) {
        return
      }

      personaClient.destroy()
    }
  }, [])

  const handleStart = useCallback(() => {
    if (!personaClient) {
      return
    }

    personaClient.open()
  }, [personaClient])

  if (!isDriverInitialized) {
    return <div />
  }

  return (
    <RecollectionSecureWrapper>
      <div css={tw`h-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] mb-4`}
          >
            <img src={DriverIcon} />
          </div>
          <Typography css={tw`mb-6 mt-2`} variant="h2">
            Verify your Driver's License
          </Typography>
          <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-4`}>
            To verify your driver's license we'll use our industry leading partner, Persona. Tap the
            button below to begin verifying.
          </Typography>
          <InfoContainer>
            Once you've finished the Persona driver's license verification, we'll automatically
            advance you to the next step
          </InfoContainer>
        </div>
        <LoadingButton css={tw`w-full h-[56px] mt-20`} variant="contained" onClick={handleStart}>
          Start verification process
        </LoadingButton>
      </div>
    </RecollectionSecureWrapper>
  )
}

export const DriverLicensePage = memo(DriverLicensePageComponent)
