import { Formik } from 'formik'
import { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { InsuranceBeneficiaryFormValues } from './InsuranceBeneficiaryForm.interface'
import { InsuranceBeneficiarySchema } from './InsuranceBeneficiaryForm.validator'
import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import { FormErrorMessage } from 'src/components/shared/form/FormErrorMessage'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import { ShipmentMajor } from '@shopify/polaris-icons'
import { InfoContainer } from 'src/components/shared/InfoContainer'
import { RecollectionUploadInsuranceBeneficiary } from 'src/constants/actionTypes'
import { CommonModal } from 'src/components/shared/CommonModal'
import { FormikSelect } from 'src/components/shared/form/formik/FormikSelect'
import {
  InsuranceBeneficiaryRelation,
  insuranceBeneficiaryRelationFormTypes,
} from 'src/constants/insuranceBeneficiary'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

const initialValues: InsuranceBeneficiaryFormValues = {
  firstName: '',
  lastName: '',
  relation: InsuranceBeneficiaryRelation.Spouse,
}

export const InsuranceBeneficiaryForm = () => {
  const dispatch = useDispatch()

  const formikRef = useRef<any>()

  const currentStep = useSelector(getCurrentRecollectionStep)

  const [showModal, setShowModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleFormSubmit = async (values: InsuranceBeneficiaryFormValues) => {
    setIsLoading(true)
    setError(null)

    try {
      await createAsyncAction(
        dispatch,
        RecollectionUploadInsuranceBeneficiary.request({ ...values, stepId: currentStep?.id }),
      )
    } catch (err: any) {
      setError(err)
    } finally {
      setIsLoading(false)
    }
  }

  const openModal = useCallback(() => {
    setShowModal(true)
  }, [])

  const closeModal = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      innerRef={formikRef}
      validationSchema={InsuranceBeneficiarySchema}
      onSubmit={handleFormSubmit}
    >
      {({ handleSubmit }) => (
        <div css={tw`h-full w-full flex flex-col justify-between`}>
          <div>
            <div
              css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
            >
              <ShipmentMajor width={20} />
            </div>
            <Typography css={tw`my-2`} variant="h2">
              Insurance beneficiary
            </Typography>
            <Typography css={tw`mb-4`} color={colors.SHADES_GREY_400}>
              You will be covered by Occupational Accident Insurance. Choose who will receive
              payouts if you are not able to.
            </Typography>
            <div css={tw`mb-4`}>
              <InfoContainer onClick={openModal}>
                Tap to discover additional details about your potential beneficiary
              </InfoContainer>
            </div>
            <div css={tw`bg-white p-4 rounded-xl`}>
              <InfoContainer color="orange" className={tw`mb-4`}>
                Please note: you cannot use your own name.
              </InfoContainer>
              <FormikTextField
                name="firstName"
                css={tw`w-full mb-4`}
                label="First name"
                placeholder="Enter your first name"
              />
              <FormikTextField
                name="lastName"
                css={tw`w-full mb-4`}
                label="Last name"
                placeholder="Enter your last name"
              />
              <div css={tw`w-full mb-4`}>
                <FormikSelect
                  name="relation"
                  label="Relation to You"
                  placeholder="Select relation type"
                  options={insuranceBeneficiaryRelationFormTypes}
                />
              </div>
            </div>
          </div>
          <FormErrorMessage css={tw`mb-4`} error={error} />
          <LoadingButton
            css={tw`w-full mt-6`}
            variant="contained"
            loading={isLoading}
            onClick={() => handleSubmit()}
          >
            <span>Submit and continue</span>
          </LoadingButton>

          <CommonModal
            isOpen={showModal}
            title="Who is your beneficiary?"
            text="A beneficiary is an individual or entity designated to receive assets, benefits, or insurance proceeds in the event of the policyholder's or account holder's death or under specified circumstances."
            onClose={closeModal}
          />
        </div>
      )}
    </Formik>
  )
}
