import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RecollectionFetchDriver } from 'src/constants/actionTypes'
import { getRecollectionAuthToken } from 'src/selectors/auth'
import { useAsyncDispatchWithErrorToast } from './useAsyncDispatchWithErrorToast'

export const initializeApplication = () => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  const authToken = useSelector(getRecollectionAuthToken)

  useEffect(() => {
    if (!authToken) {
      return
    }

    asyncDispatchWithErrorToast(RecollectionFetchDriver.request())
  }, [])
}
