import { State } from '../reducers/types'

export const getRecollectionAuthToken = (state: State): string | null =>
  state.recollectionAuth.recollectionAuthToken

export const getOnboardingAuthToken = (state: State): string | null =>
  state.onboardingAuth.onboardingAuthToken

export const getAuthToken = (state: State): string | null =>
  getRecollectionAuthToken(state) || getOnboardingAuthToken(state)
