import React, { memo, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MVROnboardingStep } from 'src/interfaces/onboarding'
import { getCurrentOnboardingStep } from 'src/selectors/onboarding'
import { MVRYardstik } from 'src/components/mvr/MVRYardstik'
import { MVRManualUpload } from 'src/components/mvr/MVRManualUploadForm'
import { createAsyncAction } from 'src/utils/reduxUtils'
import { OnboardingUploadMVR } from 'src/constants/actionTypes'
import { SecureWrapper } from '../layout/SecureWrapper'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const MVRComponent = ({ flow }: Props) => {
  const dispatch = useDispatch()

  const currentStep = useSelector(
    flow === 'recollect' ? getCurrentRecollectionStep : getCurrentOnboardingStep,
  ) as MVROnboardingStep

  const isYardstikEnabled = currentStep.metadata?.enableYardstik
  const isManualUploadEnabled = currentStep.metadata?.enableManualUpload
  const isOptional = currentStep.optional

  const [isManualUpload, setIsManualUpload] = useState<boolean>(
    currentStep && !isYardstikEnabled && isManualUploadEnabled,
  )

  const handleSkip = async () => {
    const requestBody = {
      stepId: currentStep.id,
    }

    try {
      await createAsyncAction(dispatch, OnboardingUploadMVR.request(requestBody))
    } catch (err: any) {
      //
    }
  }

  const handleBackToMainPage = useCallback(() => {
    setIsManualUpload(false)
  }, [])

  const handleOpenManualUpload = useCallback(() => {
    setIsManualUpload(true)
  }, [])

  return (
    <SecureWrapper
      flow={flow}
      onBack={isManualUpload && isYardstikEnabled ? handleBackToMainPage : undefined}
      onSkip={isOptional ? handleSkip : undefined}
    >
      {!isManualUpload && isYardstikEnabled && (
        <MVRYardstik
          flow={flow}
          onOpenManualUpload={isManualUploadEnabled ? handleOpenManualUpload : undefined}
        />
      )}
      {isManualUpload && isManualUploadEnabled && (
        <MVRManualUpload flow={flow} isYardstikEnabled={isYardstikEnabled} />
      )}
    </SecureWrapper>
  )
}

export const MVR = memo(MVRComponent)
