import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Checkbox,
  FormGroup,
  Typography,
} from '@mui/material'
import React from 'react'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  name?: string
  label?: string
  value?: (string | number | boolean)[]
  error?: boolean
  helperText?: string | false
  options: Array<BasicRadioOption>
  onChange(e: React.ChangeEvent<any>): void
}

export interface BasicRadioOption {
  label: string
  value: string | number | boolean
}

export const BasicCheckboxSelect = ({
  name,
  label,
  value,
  error,
  helperText,
  options,
  onChange,
}: Props) => {
  return (
    <FormControl css={tw`w-full`} error={error}>
      <FormLabel id={name}>{label}</FormLabel>
      <FormGroup aria-labelledby={name} onChange={onChange}>
        {options.map((option) => (
          <FormControlLabel
            key={option.value + option.label}
            value={option.value}
            css={[
              tw`w-full border rounded-lg border-solid border-[#847E96] mb-4 ml-0 p-0.5`,
              value?.includes(option.value) && tw`border-[#7F00FF]`,
            ]}
            control={<Checkbox />}
            label={
              <Typography css={tw`w-full`} color={colors.SHADES_GREY_400}>
                {option.label}
              </Typography>
            }
          />
        ))}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
