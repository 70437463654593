import dayjs from 'dayjs'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'validateCarYear', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value || value.length < 4) {
      return createError({ path, message: errorMessage })
    }

    const date = dayjs(value, { format: 'YYYY' })
    const nextYear = dayjs().add(1, 'year')
    const oldest = dayjs('1900', { format: 'YYYY' })

    return (
      (nextYear.isAfter(date) && oldest.isBefore(date)) ||
      createError({ path, message: errorMessage })
    )
  })
})

export const NonVehicleSchema = Yup.object().shape({
  type: Yup.string().required('Vehicle type is required'),
})

export const VehicleSchema = Yup.object().shape({
  type: Yup.string().required('Vehicle type is required'),
  year: Yup.string()
    .required('Vehicle year is required')
    // @ts-ignore
    .validateCarYear('Vehicle year is invalid'),
  carMake: Yup.string().required('Make is required'),
  carModel: Yup.string().required('Model is required'),
  doorsCount: Yup.string().required('Number of doors is required'),
})
