import { ReactNode, useMemo } from 'react'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { Typography, Modal, Button } from '@mui/material'
import { ChevronLeftMinor } from '@shopify/polaris-icons'

interface Props {
  isOpen?: boolean
  content: ReactNode
  onSubmit(): void
  onClose(): void
}

export const SignContractAgreementModal = ({ isOpen, content, onSubmit, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`w-full h-full flex flex-col justify-center backdrop-blur`}
    >
      <div css={tw`w-full max-h-full m-auto overflow-auto p-4`}>
        <div css={tw`max-w-[390px] m-auto flex-col p-4 pt-6 bg-white rounded-2xl`}>
          <div css={tw`h-full w-full flex flex-col`}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              css={tw`bg-[#EDEDED] hover:bg-gray-200 h-[42px] w-[82px] mb-4`}
            >
              <div css={tw`items-center flex flex-row`}>
                <ChevronLeftMinor width={20} />
                <Typography css={tw`text-black [font-weight: 600]`}>Back</Typography>
              </div>
            </Button>
            {content}
            <LoadingButton css={tw`w-full`} variant="contained" onClick={onSubmit}>
              Agree and continue
            </LoadingButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}
