import React, { memo } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { RecollectionPublicWrapper } from 'src/components/layout/recollection/RecollectionPublicWrapper'
import { colors } from 'src/theme'
import NotFoundImage from 'src/assets/images/not-found.png'

const NotFoundPageComponent = () => {
  return (
    <RecollectionPublicWrapper>
      <div css={tw`h-full flex flex-col justify-between`}>
        <img src={NotFoundImage} css={tw`h-[220px] w-[280px] self-center`} />
        <div>
          <Typography css={tw`mt-4 text-center`} variant="h2">
            404 - Page not found
          </Typography>
          <Typography css={tw`mb-4 mt-2 text-center`} color={colors.GRAY_DARK_COOL}>
            Oops! It seems you've taken a wrong turn. The page you're looking for is on a coffee
            break. Please check your URL or navigate back to our homepage. We apologize for the
            inconvenience! If the issue persists, contact our support team.
          </Typography>
        </div>
      </div>
    </RecollectionPublicWrapper>
  )
}

export const NotFoundPage = memo(NotFoundPageComponent)
