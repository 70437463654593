import React, { memo, useEffect } from 'react'
import tw from 'twin.macro'
import { Typography } from '@mui/material'
import { RecollectionPublicWrapper } from 'src/components/layout/recollection/RecollectionPublicWrapper'
import { colors } from 'src/theme'
import SuccessImage from 'src/assets/images/success.png'
import { useDispatch, useSelector } from 'react-redux'
import { getIsNewDocsUploaded } from 'src/selectors/documents'
import { RecollectionSetNewDocsUploaded } from 'src/constants/actionTypes'

const RecollectionSuccessPageComponent = () => {
  const dispatch = useDispatch()

  const isNewDocsUploaded = useSelector(getIsNewDocsUploaded)

  useEffect(() => {
    const handlePageRefresh = (event: any) => {
      event.preventDefault()
      dispatch(RecollectionSetNewDocsUploaded.success(false))
    }

    window.addEventListener('beforeunload', handlePageRefresh)
    return () => {
      window.removeEventListener('beforeunload', handlePageRefresh)
    }
  }, [])

  return (
    <RecollectionPublicWrapper>
      <div css={tw`h-full flex flex-col justify-between`}>
        <img src={SuccessImage} css={tw`h-[220px] w-[280px] self-center`} />
        <div>
          <Typography css={tw`my-4 text-center`} variant="h2">
            {isNewDocsUploaded
              ? 'Congratulations, your document submission was successful!'
              : 'All your documents are current and nothing needs to be done at the moment.'}
          </Typography>
          <Typography css={tw`text-center`} color={colors.GRAY_DARK_COOL}>
            We will keep you informed about the status of your documents through SMS notifications.
          </Typography>
        </div>
      </div>
    </RecollectionPublicWrapper>
  )
}

export const RecollectionSuccessPage = memo(RecollectionSuccessPageComponent)
