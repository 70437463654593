import { Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { RecollectionPublicWrapper } from 'src/components/layout/recollection/RecollectionPublicWrapper'
import { SignInForm } from 'src/components/auth/sign-in-form/SignInForm'
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import { RECOLLECTION_ROUTES } from 'src/constants/routes'

export const RecollectionSignInPage = () => {
  const navigate = useNavigate()
  const params = useParams()

  if (!params?.partnerName) {
    return <Navigate to={RECOLLECTION_ROUTES.NOT_FOUND_URL} />
  }

  const handleSuccess = (phone: string) => {
    navigate(`/recollection/${params.partnerName}/enter-code`, { state: { phone } })
  }

  return (
    <RecollectionPublicWrapper>
      <Typography variant="h2" css={tw`mb-2`}>
        Documents verification
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        Securely log in with your phone number to swiftly upload documents for verification.
      </Typography>
      <SignInForm
        platform="recollection"
        partnerName={params.partnerName}
        onSuccess={handleSuccess}
      />
    </RecollectionPublicWrapper>
  )
}
