import { Typography } from '@mui/material'
import React, { memo } from 'react'
import { colors } from 'src/theme'
import { addS } from 'src/utils/baseUtils'
import tw from 'twin.macro'
import CheckIcon from '../../../assets/icons/checkmark.svg'
import { InfoMultilineContainer } from 'src/components/shared/InfoMultilineContainer'

interface Props {
  icon?: any
  title: string
  text: string
  completed?: boolean
  finishTime: number
  index: number
  disabled?: boolean
  onPress?(): void
}

const StepItemComponent = ({
  title,
  finishTime,
  text,
  completed,
  icon,
  index,
  disabled,
}: Props) => {
  return (
    <>
      {disabled ? (
        <InfoMultilineContainer className={tw`mb-4 mt-4`} title="Wait for Approval">
          We will review your documents and notify you of the decision via email within 3 business
          days. If approved, you can proceed to the next steps.
        </InfoMultilineContainer>
      ) : null}
      <div
        css={tw`inline-flex flex-col items-start gap-[8px] p-[16px] bg-white rounded-[16px] overflow-hidden w-full min-h-[80px]`}
      >
        <div css={tw`inline-flex items-center gap-[16px] flex-[0_0_auto]`}>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-[#EDEDED] relative`}
          >
            {icon}
            {completed ? (
              <img css={tw`absolute bottom-0 right-[-2px]`} src={CheckIcon} />
            ) : (
              <div
                css={tw`absolute bg-[#7F00FF] w-4 h-4 rounded-lg flex justify-center items-center bottom-0 right-[-2px]`}
              >
                <Typography variant="subtitle2" color={colors.GRAY_WHITE}>
                  {index}
                </Typography>
              </div>
            )}
          </div>
          <div css={tw`flex flex-col gap-[2px]`}>
            <Typography variant="h4">{title}</Typography>
            {completed ? (
              <Typography variant="caption" color={colors.COLORS_GREEN_500}>
                Completed
              </Typography>
            ) : (
              <Typography variant="caption" color={colors.PRIMARY_PURPLE_500}>
                {`Finish in ${finishTime} ${addS(finishTime, 'minute')}`}
              </Typography>
            )}
          </div>
        </div>
        {!completed && <Typography color={colors.SHADES_GREY_400}>{text}</Typography>}
      </div>
    </>
  )
}

export const StepItem = memo(StepItemComponent)
