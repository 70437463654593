import { SelectProps } from '@mui/material'
import { useFormikContext } from 'formik'
import { BasicSelect, BasicSelectOption } from '../BasicSelect'

interface Props<T> extends Omit<SelectProps, 'name' | 'label' | 'onChange'> {
  name: keyof T
  label?: string
  options: Array<BasicSelectOption>
  onChange?(e: React.ChangeEvent<any>): void
}

export const FormikSelect = <T extends unknown>({
  name,
  label,
  options,
  onChange,
  ...rest
}: Props<T>) => {
  const { values, touched, errors, handleChange, handleBlur } = useFormikContext<T>()

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <BasicSelect
      name={name as string}
      onBlur={handleBlur(name as string)}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      label={label}
      options={options}
      {...rest}
      onChange={onChange || handleChange(name)}
      value={values[name] as unknown as string | number | undefined}
    />
  )
}
