import { FetchPartnerPublicInfo, Logout } from 'src/constants/actionTypes'
import { Action } from 'src/utils/reduxUtils'
import { PartnerState } from './types'

const initialState = {
  partner: null,
}

export const partnerReducer = (
  state: PartnerState = initialState,
  action: Action<any>,
): PartnerState => {
  switch (action.type) {
    case FetchPartnerPublicInfo.type.SUCCESS:
      return {
        ...state,
        partner: action.payload,
      }
    case Logout.type.SUCCESS:
      return initialState
    default:
      return state
  }
}
