import { memo, useEffect } from 'react'
import { useAsyncDispatchWithErrorToast } from './hooks/useAsyncDispatchWithErrorToast'
import { OnboardingFetchDriver } from './constants/actionTypes'

export const OnboardingSecureHooksContainer = memo(() => {
  const { asyncDispatchWithErrorToast } = useAsyncDispatchWithErrorToast()

  useEffect(() => {
    asyncDispatchWithErrorToast(OnboardingFetchDriver.request())
  }, [])

  return <div />
})
