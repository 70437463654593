import { RecollectionLogout, RecollectionSetAuthToken } from 'src/constants/actionTypes'
import { authService } from 'src/services/AuthService'
import { Action } from 'src/utils/reduxUtils'
import { RecollectionAuthState } from './types'

const getInitialState = (): RecollectionAuthState => ({
  recollectionAuthToken: authService.getStoredRecollectionAuthToken(),
})

export const recollectionAuthReducer = (
  state: RecollectionAuthState = getInitialState(),
  action: Action<any>,
): RecollectionAuthState => {
  switch (action.type) {
    case RecollectionSetAuthToken.type.SUCCESS:
      return {
        ...state,
        recollectionAuthToken: action.payload,
      }
    case RecollectionLogout.type.SUCCESS:
      return getInitialState()
    default:
      return state
  }
}
