import React, { memo, useCallback, useState } from 'react'
import tw from 'twin.macro'
import { ChatMajor, CircleRightMajor, MobileHamburgerMajor } from '@shopify/polaris-icons'
import { Button } from '../shared/Button'
import { MobileMenu } from './MobileMenu'
import { GetHelpModal } from './GetHelpModal'
import { LogoutModal } from './LogoutModal'
import { getAuthToken } from 'src/selectors/auth'
import { useSelector } from 'react-redux'

interface Props {
  flow: 'onboarding' | 'recollect'
}

const SettingsComponent = ({ flow }: Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isLogoutOpen, setIsLogoutOpen] = useState(false)
  const [isGetHelpOpen, setIsGetHelpOpen] = useState(false)
  const authToken = useSelector(getAuthToken)

  const openMenu = useCallback(() => {
    setIsMenuOpen(true)
  }, [])

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const openLogout = useCallback(() => {
    setIsLogoutOpen(true)
  }, [])

  const closeLogout = useCallback(() => {
    setIsLogoutOpen(false)
  }, [])

  const openGetHelp = useCallback(() => {
    setIsGetHelpOpen(true)
  }, [])

  const closeGetHelp = useCallback(() => {
    setIsGetHelpOpen(false)
  }, [])

  return (
    <>
      <Button
        leftIcon={<MobileHamburgerMajor css={tw`mx-1`} fill="white" width={20} height={20} />}
        onClick={openMenu}
        size="medium"
        className={tw`block sm:hidden`}
      />
      <div css={tw`sm:block hidden`}>
        <Button
          leftIcon={<ChatMajor fill="white" width={20} height={20} />}
          color="dark"
          size="medium"
          onClick={openGetHelp}
        >
          Get Help
        </Button>
        {authToken && (
          <Button
            leftIcon={<CircleRightMajor fill="white" width={20} height={20} />}
            size="medium"
            onClick={openLogout}
            className={tw`ml-4`}
          >
            Log out
          </Button>
        )}
      </div>
      <MobileMenu
        isOpen={isMenuOpen}
        onClose={closeMenu}
        onLogout={openLogout}
        onGetHelp={openGetHelp}
      />
      <GetHelpModal isOpen={isGetHelpOpen} onClose={closeGetHelp} />
      <LogoutModal flow={flow} isOpen={isLogoutOpen} onClose={closeLogout} />
    </>
  )
}

export const Settings = memo(SettingsComponent)
