import React, { memo } from 'react'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { InsuranceBeneficiaryForm } from 'src/components/recollection/insurance-beneficiary-form/InsuranceBeneficiaryForm'

const InsuranceBeneficiaryPageComponent = () => {
  return (
    <RecollectionSecureWrapper>
      <InsuranceBeneficiaryForm />
    </RecollectionSecureWrapper>
  )
}

export const InsuranceBeneficiaryPage = memo(InsuranceBeneficiaryPageComponent)
