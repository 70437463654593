import React, { memo } from 'react'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { GetPaidForm } from 'src/components/onboarding/get-paid-form/GetPaidForm'

const GetPaidPageComponent = () => {
  return (
    <OnboardingSecureWrapper>
      <GetPaidForm />
    </OnboardingSecureWrapper>
  )
}

export const GetPaidPage = memo(GetPaidPageComponent)
