import { Typography } from '@mui/material'
import { ChevronRightMinor, CircleAlertMajor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'
import tw from 'twin.macro'

type InfoContainerColor = 'purple' | 'orange'

export interface InfoContainerProps {
  children?: React.ReactNode
  className?: any
  color?: InfoContainerColor
  actionText?: string
  onClick?(): void
}

const containerStyles = {
  purple: tw`bg-[rgba(242, 229, 255, 1)]`,
  orange: tw`bg-[#FFEED6]`,
}

const containerHoverStyles = {
  purple: tw`bg-[rgba(226, 208, 244, 1)] cursor-pointer`,
  orange: tw`bg-[#ffb74d] cursor-pointer`,
}

const contentColors = {
  purple: colors.PRIMARY_PURPLE_500,
  orange: colors.COLORS_ORANGE_500,
}

export const InfoContainer = ({
  children,
  className,
  actionText,
  color = 'purple',
  onClick,
}: InfoContainerProps) => {
  return (
    <div
      css={[
        tw`p-4 rounded-2xl flex flex-row items-center`,
        onClick ? containerHoverStyles[color] : containerStyles[color],
        className,
      ]}
      onClick={onClick}
    >
      <div css={tw`w-5 mr-4 h-5`}>
        <CircleAlertMajor width={20} height={20} fill={contentColors[color]} />
      </div>
      <div css={tw`flex flex-col`}>
        <Typography color={contentColors[color]}>{children}</Typography>
        {actionText && (
          <Typography css={tw`mt-2`} fontWeight={600} color={contentColors[color]}>
            {actionText}
          </Typography>
        )}
      </div>
      {onClick ? (
        <div css={tw`ml-[16px] h-[20px]`}>
          <ChevronRightMinor width={20} fill={contentColors[color]} />
        </div>
      ) : null}
    </div>
  )
}
