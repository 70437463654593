export enum Endpoints {
  RecollectionSignInPhone = '/v1/gigsafe/document-recollection/sign-in',
  RecollectionSignInEnterCode = '/v1/gigsafe/document-recollection/validate-otc',

  RecollectionFetchDriver = '/v1/gigsafe/document-recollection',

  RecollectionUploadBasicInfo = '/v1/gigsafe/document-recollection/basic-info',
  RecollectionUploadInsurance = '/v1/gigsafe/document-recollection/upload-insurance',
  RecollectionUploadInsuranceDeclaration = '/v1/gigsafe/document-recollection/upload-insurance-declaration',
  RecollectionUploadVehicleRegistration = '/v1/gigsafe/document-recollection/upload-vehicle-registration',
  RecollectionUploadVaccination = '/v1/gigsafe/document-recollection/vaccination-information',
  RecollectionUploadPaymentMethod = '/v1/gigsafe/document-recollection/payment-method',
  RecollectionUploadInsuranceBeneficiary = '/v1/gigsafe/document-recollection/insurance-beneficiary',
  RecollectionUploadSignContract = '/v1/gigsafe/document-recollection/sign-contract',
  RecollectionUploadTraining = '/v1/gigsafe/document-recollection/complete-trainings',
  RecollectionStitchCompletePayment = '/v1/gigsafe/document-recollection/complete-stitch-payment',
  RecollectionUploadForm = '/v1/gigsafe/document-recollection/form',
  RecollectionUploadBGC = '/v1/gigsafe/document-recollection/upload-background-check',
  RecollectionUploadMVR = '/v1/gigsafe/document-recollection/upload-mvr',
  RecollectionUploadEIN = '/v1/gigsafe/document-recollection/handle-business-entity',

  OnboardingSignInPhone = '/v1/gigsafe/onboarding/driver/sign-in',
  OnboardingSignUp = '/v1/gigsafe/onboarding/driver/sign-up',
  OnboardingSignInEnterCode = '/v1/gigsafe/onboarding/driver/validate-otc',

  OnboardingFetchDriver = '/v1/gigsafe/onboarding/driver',

  // OnboardingUploadDriverLicense = '/v1/gigsafe/onboarding/driver/',
  OnboardingUploadQuestionnaire = '/v1/gigsafe/onboarding/driver/questionnaire',
  OnboardingUploadForm = '/v1/gigsafe/onboarding/driver/form',
  OnboardingUploadVehicleInsurance = '/v1/gigsafe/onboarding/driver/upload-insurance',
  OnboardingUploadInsuranceDeclaration = '/v1/gigsafe/onboarding/driver/upload-insurance-declaration',
  OnboardingUploadVehicleRegistration = '/v1/gigsafe/onboarding/driver/upload-vehicle-registration',
  OnboardingUploadVaccination = '/v1/gigsafe/onboarding/driver/vaccination-information',
  OnboardingUploadPaymentMethod = '/v1/gigsafe/onboarding/driver/payment-method',
  OnboardingUploadInsuranceBeneficiary = '/v1/gigsafe/onboarding/driver/insurance-beneficiary',
  OnboardingUploadSignContract = '/v1/gigsafe/onboarding/driver/sign-contract',
  OnboardingUploadTraining = '/v1/gigsafe/onboarding/driver/complete-trainings',
  OnboardingUploadMVR = '/v1/gigsafe/onboarding/driver/upload-mvr',
  OnboardingUploadEIN = '/v1/gigsafe/onboarding/driver/handle-business-entity',
  OnboardingUploadBGC = '/v1/gigsafe/onboarding/driver/upload-background-check',

  FetchPersonaInquiry = '/v1/gigsafe/integrations/persona/inquiry',
  SetPersonaSessionToken = '/v1/gigsafe/integrations/persona/inquiry/sessionToken',
  SetPersonaClientCompletedInquiry = '/v1/gigsafe/integrations/persona/inquiry/client-completed',

  FetchYardstikReport = '/v1/gigsafe/integrations/yardstik/report',

  DocusignStartSigning = '/v1/gigsafe/integrations/docusign/start-signing',
  DocusignCompleteSigning = '/v1/gigsafe/integrations/docusign/complete-signing',
  DocusignDeclineSigning = '/v1/gigsafe/integrations/docusign/decline-signing',

  StitchStartFlow = '/v1/gigsafe/payments/driver/start-stitch-flow',
  StitchCompletePayment = '/v1/gigsafe/onboarding/driver/complete-stitch-payment',

  FetchPartnerInfo = '/v1/partners/public/:partnerName',
}
