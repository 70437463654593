import dayjs from 'dayjs'
import * as Yup from 'yup'

Yup.addMethod(Yup.string, 'validateDate', function (errorMessage) {
  return this.test(`test-phone`, errorMessage, function (value) {
    const { path, createError } = this

    if (!value) {
      return createError({ path, message: errorMessage })
    }

    const date = dayjs(value, { format: 'MM/DD/YYYY' })
    const today = dayjs()
    const oldest = dayjs('2018', { format: 'YYYY' })

    return (
      (today.isAfter(date) && oldest.isBefore(date)) || createError({ path, message: errorMessage })
    )
  })
})

export const VaccinationSchema = Yup.object().shape({
  brand: Yup.string().required('Brand is required'),
  date: Yup.string()
    .required('Date of most recent vaccination is required')
    // @ts-ignore
    .validateDate('Date of most recent vaccination is invalid'),
})
