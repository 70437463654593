export enum VaccineType {
  Comirnaty = 'Comirnaty',
  Moderna = 'Moderna',
  Novavax = 'Novavax',
  Pfizer = 'Pfizer-BioNTech',
  Spikevax = 'Spikevax',
}

export const vaccineFormTypes = [
  { label: 'Comirnaty (COVID-19 Vaccine, mRNA)', value: VaccineType.Comirnaty },
  { label: 'Moderna COVID-19 Vaccine', value: VaccineType.Moderna },
  { label: 'Novavax COVID-19 Vaccine, Adjuvanted', value: VaccineType.Novavax },
  { label: 'Pfizer-BioNTech COVID-19 Vaccine', value: VaccineType.Pfizer },
  { label: 'Spikevax (COVID-19 Vaccine, mRNA)', value: VaccineType.Spikevax },
]
