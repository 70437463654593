import { RadioGroupProps } from '@mui/material'
import { useFormikContext } from 'formik'
import { BasicRadioGroup, BasicRadioOption } from '../BasicRadioGroup'

interface Props<T> extends Omit<RadioGroupProps, 'name' | 'label' | 'onChange'> {
  name: keyof T
  label?: string
  options: Array<BasicRadioOption>
  onChange?(e: React.ChangeEvent<any>): void
}

export const FormikRadioGroup = <T extends unknown>({
  name,
  label,
  options,
  onChange,
  ...rest
}: Props<T>) => {
  const { values, touched, errors, handleChange } = useFormikContext<T>()

  const isTouched = touched[name] as boolean
  const error = errors[name] as string | undefined

  return (
    <BasicRadioGroup
      name={name as string}
      error={Boolean(isTouched && error)}
      helperText={isTouched && error}
      label={label}
      options={options}
      {...rest}
      onChange={onChange || handleChange(name)}
      value={values[name] as unknown as string | number | undefined}
    />
  )
}
