import { ReactNode } from 'react'
import tw from 'twin.macro'
import background from 'src/assets/images/background.svg'

interface Props {
  children: ReactNode
}

export const RecollectionSecureWrapper = ({ children }: Props) => {
  return (
    <div css={tw`h-full w-full flex flex-col`}>
      <div css={tw`flex flex-1 bg-[#300258] items-center justify-center flex-col lg:py-16`}>
        <img src={background} css={tw`fixed top-0 bottom-0 z-0 h-full`} />
        <div
          css={tw`bg-white sm:max-w-[390px] mx-4 sm:min-h-[80vh] w-full flex flex-1 sm:flex-none flex-col sm:rounded-2xl overflow-hidden z-10 sm:my-4`}
        >
          <div css={tw`flex flex-1 bg-[#F6F6F6] items-center justify-center flex-col p-4 `}>
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}
