import { Checkbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material'
import React from 'react'

interface Props {
  name?: string

  label?: string
  value?: string | number
  error?: boolean
  helperText?: string | false
  onChange(e: React.ChangeEvent<any>): void
}

export const BasicCheckbox = ({ name, label, value, error, helperText, onChange }: Props) => {
  return (
    <FormControl error={error}>
      <FormControlLabel
        control={<Checkbox name={name} checked={!!value} value={true} onChange={onChange} />}
        label={label}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}
