import React, { memo, ReactNode, useMemo } from 'react'
import { Button, Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'

interface Props {
  isOpen?: boolean
  title?: string
  text?: string
  subtext?: string
  image?: any
  buttons?: ReactNode
  onClose(): void
}

const CommonModalComponent = ({ isOpen, title, text, subtext, image, buttons, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur p-4`}
    >
      <div css={tw`max-w-[390px] mx-4 flex-col p-4 pt-6 bg-white rounded-2xl`}>
        {title && <Typography variant="h2">{title}</Typography>}
        {text && (
          <Typography css={tw`mt-2`} color={colors.SHADES_GREY_400}>
            {text}
          </Typography>
        )}
        {subtext && (
          <Typography css={tw`mt-6`} color={colors.SHADES_GREY_400}>
            {subtext}
          </Typography>
        )}
        {image && <img css={tw`mt-6 w-full`} src={image} />}
        {buttons || (
          <Button fullWidth onClick={onClose} variant="contained" css={tw`bg-black mt-6`}>
            <Typography color={colors.GRAY_WHITE}>Close</Typography>
          </Button>
        )}
      </div>
    </Modal>
  )
}

export const CommonModal = memo(CommonModalComponent)
