import { OnboardingStep, TrainingOnboardingStep } from 'src/interfaces/onboarding'
import { State } from '../reducers/types'
import {
  ONBOARDING_STEP_TIME_TO_COMPLETE,
  OnboardingStatus,
  OnboardingStepType,
} from 'src/constants/onboarding'
import {
  clientSortSteps,
  backendSortSteps,
  sortByDisabled,
  sortByCreated,
} from 'src/utils/onboarding'

export const getRecollectionSteps = (state: State): OnboardingStep[] => {
  const steps =
    state.recollectionDriver.recollectionDriver?.recollection?.recollectionCollectDataSteps
      ?.filter((item) => item.collectDataStep.status !== OnboardingStatus.Archived)
      .map((item) => item.collectDataStep) || []

  const sortedSteps = steps.every((item) => item.order === 0)
    ? clientSortSteps(steps)
    : backendSortSteps(steps)

  return sortByCreated(sortByDisabled(sortedSteps))
}

export const getCurrentRecollectionStep = (state: State): OnboardingStep | null => {
  const steps = getRecollectionSteps(state)

  return steps.find((item) => item.status === OnboardingStatus.Created) || null
}

export const getOnlyDelayedStepsLeft = (state: State): boolean => {
  const steps = getRecollectionSteps(state)

  return steps.every(
    (item) =>
      item.status !== OnboardingStatus.Created ||
      (item.disabled === true && item.status === OnboardingStatus.Created),
  )
}

export const getRecollectionStepsRemainingTimeToComplete = (state: State): number => {
  const steps = getRecollectionSteps(state)

  return steps.reduce(
    (res, item) =>
      (res +=
        (item.status === OnboardingStatus.Created && ONBOARDING_STEP_TIME_TO_COMPLETE[item.type]) ||
        0),
    0,
  )
}

export const getRecollectionStepIndex =
  (stepType: OnboardingStepType) =>
  (state: State): number => {
    const steps = getRecollectionSteps(state)

    return steps.findIndex((item) => item.type === stepType) + 1
  }

export const getRecollectionStepsCount = (state: State): number =>
  (state.recollectionDriver.recollectionDriver?.recollection?.recollectionCollectDataSteps || [])
    .length

export const getIsRecollectionStarted = (state: State): boolean => {
  const steps = getRecollectionSteps(state)

  return steps.some((item) => item.status !== OnboardingStatus.Created)
}

export const getIsRecollectionFinished = (state: State): boolean => {
  const steps = getRecollectionSteps(state)

  return steps.every((item) => item.status !== OnboardingStatus.Created)
}

export const getIsRecollectionFailed = (state: State): boolean => {
  const steps = getRecollectionSteps(state)

  return steps.some((item) => item.status === OnboardingStatus.Failed)
}

const isTrainingStep = (step: OnboardingStep): step is TrainingOnboardingStep =>
  step.type === OnboardingStepType.Training

export const getRecollectionTrainingStep = (state: State): TrainingOnboardingStep | null => {
  const steps = getRecollectionSteps(state)

  return steps.find(isTrainingStep) || null
}

export const getRecollectionStepByType =
  <T>(stepType: OnboardingStepType) =>
  (state: State): T | null => {
    const steps = getRecollectionSteps(state)

    return (steps.find((item) => item.type === stepType) as T) || null
  }
