import React, { memo, useMemo } from 'react'
import { Modal, Typography } from '@mui/material'
import tw from 'twin.macro'
import { colors } from 'src/theme'
import FailedImage from 'src/assets/images/failed.png'
import { Button } from '../shared/Button'

interface Props {
  isOpen?: boolean
  attemptsLeft: number
  onClose(): void
}

const FailedAttemptModalComponent = ({ isOpen, attemptsLeft, onClose }: Props) => {
  const container = useMemo(() => document.getElementById('root'), [])

  return (
    <Modal
      container={container}
      open={Boolean(isOpen)}
      onClose={onClose}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      css={tw`flex justify-center items-center backdrop-blur p-4`}
    >
      <div css={tw`max-w-[390px] mx-4 flex-col p-4 pt-6 bg-white rounded-2xl`}>
        <div css={tw`h-full flex flex-col justify-between px-4`}>
          <img src={FailedImage} css={tw`h-[220px] w-[280px] self-center`} />
          <div css={tw`mb-6`}>
            <Typography css={tw`my-4 text-center`} variant="h2">
              Something's off!
            </Typography>
            <Typography css={tw`text-center`} color={colors.GRAY_DARK_COOL}>
              Some of your answers don't match our expectations. Please try again — you have{' '}
              <Typography color={colors.BASICS_BLACK} fontWeight={700}>
                {attemptsLeft} attempts left.
              </Typography>
            </Typography>
          </div>
          <Button onClick={onClose}>Try again</Button>
        </div>
      </div>
    </Modal>
  )
}

export const FailedAttemptModal = memo(FailedAttemptModalComponent)
