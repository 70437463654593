import { call, put, takeEvery } from 'redux-saga/effects'
import { AsyncAction } from 'src/utils/reduxUtils'
import { RecollectionFetchYardstikReport } from 'src/constants/actionTypes'
import { ApiService, callRecollectionSecureApi } from '../api/callApi'
import { Endpoints } from 'src/constants/endpoints'
import { YardstikReport } from 'src/interfaces/yardstik'

interface FetchYardstikReportRequest {
  stepId: string
}

function* onFetchYardstikReport(action: AsyncAction<FetchYardstikReportRequest, YardstikReport>) {
  try {
    const { payload }: { payload: YardstikReport } = yield call(
      callRecollectionSecureApi,
      Endpoints.FetchYardstikReport,
      {
        method: 'POST',
        apiService: ApiService.Paraworks,
        query: {
          stepId: action.payload.stepId,
          flow: 'recollection',
        },
      },
    )

    yield put(RecollectionFetchYardstikReport.success())

    if (action.next) {
      action.next(null, payload)
    }
  } catch (err: any) {
    const errorMessage = err?.payload?.message || 'Failed to fetch background check report'

    yield put(RecollectionFetchYardstikReport.failed(err))

    if (action.next) {
      action.next(errorMessage)
    }
  }
}

export function* recollectionYardstikSaga() {
  yield takeEvery(RecollectionFetchYardstikReport.type.REQUEST, onFetchYardstikReport)
}
