import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { DriverLicensePage } from './DriverLicensePage'
import { InsurancePage } from './InsurancePage'
import { InsuranceDeclarationPage } from './InsuranceDeclarationPage'
import { VehicleRegistrationPage } from './VehicleRegistrationPage'
import { VaccinationPage } from './VaccinationPage'
import { BGCPage } from './BGCPage'
import { GetPaidPage } from './GetPaidPage'
import { InsuranceBeneficiaryPage } from './InsuranceBeneficiaryPage'
import { SignContractPage } from './SignContractPage'
import { TakeTrainingPage } from './TakeTrainingPage'
import { getIsRecollectionDriverInitialized } from 'src/selectors/driver'
import { RecollectionSuccessPage } from '../../RecollectionSuccessPage'
import { LoadingPage } from '../../LoadingPage'
import { StitchPaymentPage } from './StitchPaymentPage'
import { OnboardingStepType } from 'src/constants/onboarding'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'
import { FormPage } from 'src/pages/recollection/docs/FormPage'
import { MVRPage } from './MVRPage'
import { EIN } from 'src/components/ein/EIN'

const stepsPageMap = {
  [OnboardingStepType.Questionnaire]: () => null,
  [OnboardingStepType.DriverLicense]: DriverLicensePage,
  [OnboardingStepType.VehicleInsurance]: InsurancePage,
  [OnboardingStepType.InsuranceDeclaration]: InsuranceDeclarationPage,
  [OnboardingStepType.VehicleRegistration]: VehicleRegistrationPage,
  [OnboardingStepType.Vaccination]: VaccinationPage,
  [OnboardingStepType.BGC]: BGCPage,
  [OnboardingStepType.PaymentMethod]: GetPaidPage,
  [OnboardingStepType.StitchPayment]: StitchPaymentPage,
  [OnboardingStepType.InsuranceBeneficiary]: InsuranceBeneficiaryPage,
  [OnboardingStepType.SignContract]: SignContractPage,
  [OnboardingStepType.Training]: TakeTrainingPage,
  [OnboardingStepType.MVR]: MVRPage,
  [OnboardingStepType.EIN]: () => <EIN flow="recollect" />,
  [OnboardingStepType.Form]: FormPage,
}

const DocsReuploadPageComponent = () => {
  const currentStep = useSelector(getCurrentRecollectionStep)
  const isDriverInitialized = useSelector(getIsRecollectionDriverInitialized)

  if (!isDriverInitialized) {
    return <LoadingPage />
  }

  if (!currentStep) {
    return <RecollectionSuccessPage />
  }

  const StepComponent = stepsPageMap[currentStep.type]

  if (StepComponent) {
    return <StepComponent />
  }

  return <RecollectionSuccessPage />
}

export const DocsReuploadPage = memo(DocsReuploadPageComponent)
