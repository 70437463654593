import React, { memo } from 'react'
import { OnboardingSecureWrapper } from 'src/components/layout/onboarding/OnboardingSecureWrapper'
import { InsuranceBeneficiaryForm } from 'src/components/onboarding/insurance-beneficiary-form/InsuranceBeneficiaryForm'

const InsuranceBeneficiaryPageComponent = () => {
  return (
    <OnboardingSecureWrapper>
      <InsuranceBeneficiaryForm />
    </OnboardingSecureWrapper>
  )
}

export const InsuranceBeneficiaryPage = memo(InsuranceBeneficiaryPageComponent)
