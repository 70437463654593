import { Typography } from '@mui/material'
import { memo, useCallback, useMemo, useState } from 'react'
import tw from 'twin.macro'
import { LoadingButton } from '@mui/lab'
import { colors } from 'src/theme'
import { RecollectionSecureWrapper } from 'src/components/layout/recollection/RecollectionSecureWrapper'
import { BlogMajor } from '@shopify/polaris-icons'
import { useDispatch, useSelector } from 'react-redux'
import { DocusignSigningField, DocusignStartSigningResponse } from 'src/interfaces/docusign'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  RecollectionDocusignCompleteSigning,
  RecollectionDocusignDeclineSigning,
  RecollectionDocusignStartSigning,
  RecollectionFetchDriver,
} from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { captureSentryError } from 'src/utils/sentry'
import { DocusignFieldsFormModal } from 'src/components/docusign-field-form-modal/DocusignFieldsFormModal'
import { SignContractAgreementModal } from 'src/components/SignContractAgreementModal'
import { getOnboardingDriver } from 'src/selectors/driver'
import { partnerAgreementContentMap, PartnerNameType } from 'src/constants/signContract'
import { getCurrentRecollectionStep } from 'src/selectors/recollection'

const DOCUSIGN_CONTAINER_ID = 'docusign-container'

interface SigningField {
  tabLabel: string
  value: string
}

const SignContractPageComponent = () => {
  const dispatch = useDispatch()

  const driver = useSelector(getOnboardingDriver)
  const currentStep = useSelector(getCurrentRecollectionStep)

  const [docusignSigningData, setDocusignSigningData] =
    useState<DocusignStartSigningResponse | null>(null)
  const [isStartLoading, setIsStartLoading] = useState<boolean>(false)
  const [isFieldsModalOpen, setIsFieldsModalOpen] = useState<boolean>(false)
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState<boolean>(false)
  const [modalFields, setModalFields] = useState<Array<DocusignSigningField>>([])

  const agreementContent = useMemo(
    () =>
      driver?.partner?.name
        ? partnerAgreementContentMap[driver?.partner?.name as PartnerNameType]
        : undefined,
    [driver?.partner?.name],
  )

  const handleStartSigning = useCallback(
    async (signingFields?: Array<SigningField>) => {
      setIsStartLoading(true)

      try {
        const signingResponse: DocusignStartSigningResponse | undefined = await createAsyncAction(
          dispatch,
          RecollectionDocusignStartSigning.request({ signingFields, stepId: currentStep?.id }),
        )

        if (!signingResponse) {
          throw new Error('Signing is not available')
        }

        if (signingResponse.retryFetchDriver) {
          await createAsyncAction(dispatch, RecollectionFetchDriver.request())

          return
        }

        if (signingResponse.signingFields?.length) {
          setModalFields(signingResponse.signingFields)
          setIsFieldsModalOpen(true)
          setIsStartLoading(false)

          return
        }

        setDocusignSigningData(signingResponse)
        handleLoadDocusign(signingResponse)
      } catch (err) {
        showToast('Failed to load signing page, please try again later', {
          variant: 'error',
        })

        setIsStartLoading(false)
      }
    },
    [currentStep],
  )

  const handleCompleteSigning = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RecollectionDocusignCompleteSigning.request({ stepId: currentStep?.id }),
      )
      await createAsyncAction(dispatch, RecollectionFetchDriver.request())
    } catch (err) {
      showToast('Failed to finish signing, please try again later', {
        variant: 'error',
      })
    }
  }, [currentStep])

  const handleDeclineSigning = useCallback(async () => {
    try {
      await createAsyncAction(
        dispatch,
        RecollectionDocusignDeclineSigning.request({ stepId: currentStep?.id }),
      )
      await createAsyncAction(dispatch, RecollectionFetchDriver.request())
    } catch (err) {
      // ignore this error
    }
  }, [currentStep])

  const handleLoadDocusign = useCallback(async (payload: DocusignStartSigningResponse) => {
    if (!payload.signingData?.url) {
      return
    }

    try {
      const docusign = await window.DocuSign.loadDocuSign(
        process.env.REACT_APP_DOCUGIGN_INTEGRATION_KEY as string,
      )

      const signing = docusign.signing({
        url: payload.signingData.url,
        displayFormat: 'focused',
        style: {
          branding: {
            primaryButton: {
              backgroundColor: '#333',
              color: '#fff',
            },
          },
          signingNavigationButton: {
            finishText: 'Continue',
            position: 'bottom-center',
          },
        },
      })

      signing.on('ready', () => {
        setIsStartLoading(false)
      })

      signing.on('sessionEnd', async (event) => {
        if (event.sessionEndType === 'signing_complete') {
          handleCompleteSigning()
          return
        }

        if (event.sessionEndType === 'ttl_expired') {
          setDocusignSigningData(null)
          showToast('Signing link is expired, please start signing again', {
            variant: 'error',
          })

          return
        }

        if (event.sessionEndType === 'decline') {
          await handleDeclineSigning()
          setDocusignSigningData(null)
          showToast('Signing is declined, please start signing again or contact support', {
            variant: 'error',
          })

          return
        }
      })

      signing.mount(`#${DOCUSIGN_CONTAINER_ID}`)
    } catch (err: any) {
      setIsStartLoading(false)
      captureSentryError(err, {
        message: '[handleLoadDocusign]',
      }) //
    }
  }, [])

  const handleCloseFieldsModal = useCallback(() => {
    setIsFieldsModalOpen(false)
  }, [])

  const handleSubmitFieldsModal = useCallback(
    async (signingFields?: Array<SigningField>) => {
      await handleStartSigning(signingFields)

      setIsFieldsModalOpen(false)
    },
    [handleStartSigning],
  )

  const handleOpenAgreementModal = useCallback(() => {
    setIsAgreementModalOpen(true)
  }, [])

  const handleCloseAgreementModal = useCallback(() => {
    setIsAgreementModalOpen(false)
  }, [])

  const handleStartSigningPress = useCallback(() => {
    if (agreementContent) {
      handleOpenAgreementModal()

      return
    }

    handleStartSigning()
  }, [agreementContent, handleStartSigning])

  const handleSubmitAgreement = useCallback(() => {
    handleCloseAgreementModal()
    handleStartSigning()
  }, [handleStartSigning])

  return (
    <RecollectionSecureWrapper>
      <div css={tw`h-full flex flex-col justify-between`}>
        <div>
          <div
            css={tw`w-11 h-11 flex justify-center items-center [border-radius: 22px] bg-white mb-4`}
          >
            <BlogMajor width={20} />
          </div>
          <Typography css={tw`mb-6`} variant="h2">
            Sign the contract
          </Typography>
          <Typography color={colors.GRAY_DARK_COOL} css={tw`mb-12`}>
            Sign your contracts and relevant documents via Docusign. Tap the button below to begin.
          </Typography>
        </div>
        <LoadingButton
          css={tw`w-full`}
          variant="contained"
          loading={isStartLoading}
          onClick={handleStartSigningPress}
        >
          <span>Sign the contract</span>
        </LoadingButton>
      </div>
      <div
        id={DOCUSIGN_CONTAINER_ID}
        css={[
          tw`fixed top-0 pt-16 left-0 h-full w-full bg-[#FFFFFF] overflow-auto`,
          docusignSigningData ? undefined : tw`opacity-0 hidden`,
        ]}
      />
      <DocusignFieldsFormModal
        isOpen={isFieldsModalOpen}
        fields={modalFields}
        onClose={handleCloseFieldsModal}
        onSubmit={handleSubmitFieldsModal}
      />
      <SignContractAgreementModal
        isOpen={isAgreementModalOpen}
        content={agreementContent}
        onClose={handleCloseAgreementModal}
        onSubmit={handleSubmitAgreement}
      />
    </RecollectionSecureWrapper>
  )
}

export const SignContractPage = memo(SignContractPageComponent)
