import { Routes, Route, Navigate, BrowserRouter } from 'react-router-dom'
import React from 'react'
import { useSelector } from 'react-redux'
import { getOnboardingAuthToken, getRecollectionAuthToken } from 'src/selectors/auth'
import { OnboardingSignInPage } from 'src/pages/onboarding/auth/OnboardingSignInPage'
import { OnboardingEnterCodePage } from 'src/pages/onboarding/auth/OnboardingEnterCodePage'
import { OnboardingHomePage } from 'src/pages/onboarding/OnboardingHome'
// import { DriverLicensePage } from 'src/pages/onboarding/docs/DriverLicensePage'
// import { VehicleInsurancePage } from 'src/pages/onboarding/docs/VehicleInsurancePage'
// import { InsuranceDeclarationPage } from 'src/pages/onboarding/docs/InsuranceDeclarationPage'
// import { VehicleRegistrationPage } from 'src/pages/onboarding/docs/VehicleRegistrationPage'
// import { VaccinationPage } from 'src/pages/onboarding/docs/VaccinationPage'
// import { BGCPage } from 'src/pages/onboarding/docs/BGCPage'
// import { GetPaidPage } from 'src/pages/onboarding/docs/GetPaidPage'
// import { InsuranceBeneficiaryPage } from 'src/pages/onboarding/docs/InsuranceBeneficiaryPage'
// import { SignContractPage } from 'src/pages/onboarding/docs/SignContractPage'
// import { TakeTrainingPage } from 'src/pages/onboarding/docs/TakeTrainingPage'
import { NotFoundPage } from 'src/pages/NotFoundPage'
import { LoggedOutPage } from 'src/pages/LoggedOutPage'
import { DocsReuploadPage } from 'src/pages/recollection/docs/DocsReuploadPage'
import { RecollectionSignInPage } from 'src/pages/recollection/auth/RecollectionSignInPage'
import { RecollectionEnterCodePage } from 'src/pages/recollection/auth/RecollectionEnterCodePage'
import { OnboardingSecureHooksContainer } from './OnboardingSecureHooksContainer'
import { RecollectionSecureHooksContainer } from './RecollectionSecureHooksContainer'
import { OnboardingDocsPage } from './pages/onboarding/docs/OnboardingDocsPage'
import { ONBOARDING_ROUTES, RECOLLECTION_ROUTES } from './constants/routes'
import { OnboardingSignUpPage } from './pages/onboarding/auth/OnboardingSignUpPage'

export const AppRoutes = () => {
  const onboardingAuthToken = useSelector(getOnboardingAuthToken)
  const recollectionAuthToken = useSelector(getRecollectionAuthToken)

  return (
    <BrowserRouter>
      <Routes>
        {onboardingAuthToken ? (
          <>
            <Route path={ONBOARDING_ROUTES.HOME_URL} element={<OnboardingHomePage />} />
            <Route path={ONBOARDING_ROUTES.DOCS_URL} element={<OnboardingDocsPage />} />
            {/* <Route path="driver-license" element={<DriverLicensePage />} />
              <Route path="vehicle-insurance" element={<VehicleInsurancePage />} />
              <Route path="insurance-declaration" element={<InsuranceDeclarationPage />} />
              <Route path="vehicle-registration" element={<VehicleRegistrationPage />} />
              <Route path="vaccination" element={<VaccinationPage />} />
              <Route path="bgc" element={<BGCPage />} />
              <Route path="get-paid" element={<GetPaidPage />} />
              <Route path="insurance-beneficiary" element={<InsuranceBeneficiaryPage />} />
              <Route path="sign-contract" element={<SignContractPage />} />
              <Route path="take-training" element={<TakeTrainingPage />} /> */}
            <Route path="onboarding/*" element={<Navigate to={ONBOARDING_ROUTES.HOME_URL} />} />
          </>
        ) : (
          <>
            <Route path={ONBOARDING_ROUTES.SIGN_IN_URL} element={<OnboardingSignInPage />} />
            <Route path={ONBOARDING_ROUTES.SIGN_UP_URL} element={<OnboardingSignUpPage />} />
            <Route path={ONBOARDING_ROUTES.ENTER_CODE_URL} element={<OnboardingEnterCodePage />} />
            <Route path={ONBOARDING_ROUTES.NOT_FOUND_URL} element={<NotFoundPage />} />
            <Route path={ONBOARDING_ROUTES.LOGGED_OUT_URL} element={<LoggedOutPage />} />
            <Route
              path="onboarding/*"
              element={<Navigate to={ONBOARDING_ROUTES.NOT_FOUND_URL} />}
            />
          </>
        )}
        {recollectionAuthToken ? (
          <>
            <Route path={RECOLLECTION_ROUTES.DOCS_REUPLOAD_URL} element={<DocsReuploadPage />} />
            <Route
              path="recollection/*"
              element={<Navigate to={RECOLLECTION_ROUTES.DOCS_REUPLOAD_URL} />}
            />
          </>
        ) : (
          <>
            <Route path={RECOLLECTION_ROUTES.SIGN_IN_URL} element={<RecollectionSignInPage />} />
            <Route
              path={RECOLLECTION_ROUTES.ENTER_CODE_URL}
              element={<RecollectionEnterCodePage />}
            />
            <Route path={RECOLLECTION_ROUTES.NOT_FOUND_URL} element={<NotFoundPage />} />
            <Route path={RECOLLECTION_ROUTES.LOGGED_OUT_URL} element={<LoggedOutPage />} />
            <Route
              path="recollection/*"
              element={<Navigate to={RECOLLECTION_ROUTES.NOT_FOUND_URL} />}
            />
          </>
        )}
        <Route path="not-found" element={<NotFoundPage />} />
        <Route path="logged-out" element={<LoggedOutPage />} />
        <Route path="*" element={<Navigate to="not-found" />} />
      </Routes>
      {onboardingAuthToken && <OnboardingSecureHooksContainer />}
      {recollectionAuthToken && <RecollectionSecureHooksContainer />}
    </BrowserRouter>
  )
}
