import { FormHelperText, Typography } from '@mui/material'
import { FormControl, MenuItem, Select } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'

interface Props {
  name?: string
  css?: any
  label?: string
  placeholder?: string
  value?: string | number
  error?: boolean
  helperText?: string | false
  options: Array<BasicSelectOption>
  onChange(e: React.ChangeEvent<any>): void
  onBlur?: (e: React.FocusEvent<any>) => void
}

export interface BasicSelectOption {
  label: string
  value: string | number
}

export const BasicSelect = ({
  name,
  css,
  label,
  placeholder,
  value,
  error,
  helperText,
  options,
  onChange,
  onBlur,
}: Props) => {
  const handleChange = (event: any) => {
    onChange(event)
  }

  const handleBlur = (event: any) => {
    if (onBlur) {
      onBlur(event)
    }
  }

  return (
    <div css={css}>
      <Typography css={tw`mb-1`} variant="h6" color={colors.GRAY_COOL_GRAY}>
        {label}
      </Typography>
      <FormControl fullWidth error={error}>
        <Select
          name={name}
          labelId="select-label"
          id="simple-select"
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    </div>
  )
}
