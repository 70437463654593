import { FormikTextField } from 'src/components/shared/form/formik/FormikTextField'
import tw from 'twin.macro'
import { DocusignSigningField } from 'src/interfaces/docusign'
import {
  DocusignFieldTabLabel,
  DocusignFieldNames,
  DocusignFieldPlaceholders,
  DocusignFieldSelectOptions,
} from 'src/constants/docusign'
import { FormikSelect } from '../shared/form/formik/FormikSelect'
import { FormikRadioGroup } from '../shared/form/formik/FormikRadioGroup'
import { FormikCheckbox } from '../shared/form/formik/FormikCheckbox'

const masks = {
  [DocusignFieldTabLabel.phoneNumber]: undefined,
  [DocusignFieldTabLabel.phoneNumberHome]: undefined,
  [DocusignFieldTabLabel.email]: undefined,
  [DocusignFieldTabLabel.fullName]: undefined,
  [DocusignFieldTabLabel.address]: undefined,
  [DocusignFieldTabLabel.city]: undefined,
  [DocusignFieldTabLabel.state]: undefined,
  [DocusignFieldTabLabel.zipcode]: [/\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.cityStateZipcode]: undefined,
  [DocusignFieldTabLabel.ssn]: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.tin]: ['9', /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.fein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.dob]: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.beneficiary]: undefined,
  [DocusignFieldTabLabel.beneficiaryRelationship]: undefined,
  [DocusignFieldTabLabel.insuranceCarrier]: undefined,
  [DocusignFieldTabLabel.otherInsurancePlan]: undefined,
  [DocusignFieldTabLabel.eSignatureAck]: undefined,
  [DocusignFieldTabLabel.businessName]: undefined,
  [DocusignFieldTabLabel.ein]: [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleYear]: [/\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleMake]: undefined,
  [DocusignFieldTabLabel.vehicleVIN]: undefined,
  [DocusignFieldTabLabel.vehicleLicensePlate]: undefined,
  [DocusignFieldTabLabel.vehicleYear2]: [/\d/, /\d/, /\d/, /\d/],
  [DocusignFieldTabLabel.vehicleMake2]: undefined,
  [DocusignFieldTabLabel.vehicleVIN2]: undefined,
  [DocusignFieldTabLabel.vehicleLicensePlate2]: undefined,
}

interface Props {
  field: DocusignSigningField
}

const selectFields = [DocusignFieldTabLabel.state]

export const DocusignFieldsFormModalInput = ({ field }: Props) => {
  if (selectFields.includes(field.tabLabel) && DocusignFieldSelectOptions[field.tabLabel]) {
    return (
      <div css={tw`w-full mb-6`}>
        <FormikSelect
          name={field.tabLabel}
          label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
          placeholder={DocusignFieldPlaceholders[field.tabLabel]}
          options={DocusignFieldSelectOptions[field.tabLabel]}
        />
      </div>
    )
  }

  if (field.tabType === 'radiogroup') {
    return (
      <div css={tw`w-full mb-6`}>
        <FormikRadioGroup
          name={field.tabLabel}
          label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
          options={field.radios.map((radio) => ({ label: radio.value, value: radio.value }))}
        />
      </div>
    )
  }

  if (field.tabType === 'checkbox') {
    return (
      <div css={tw`mb-6`}>
        <FormikCheckbox
          css={tw`w-full mb-6`}
          name={field.tabLabel}
          label={DocusignFieldNames[field.tabLabel]}
        />
      </div>
    )
  }

  return (
    <FormikTextField
      name={field.tabLabel}
      css={tw`w-full mb-6`}
      label={`${DocusignFieldNames[field.tabLabel]}${field.required ? '*' : ''}`}
      mask={masks[field.tabLabel]}
      placeholder={DocusignFieldPlaceholders[field.tabLabel]}
    />
  )
}
