import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OnboardingDriver } from 'src/interfaces/onboarding'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingFetchDriver,
  OnboardingFetchYardstikReport,
  RecollectionFetchDriver,
  RecollectionFetchYardstikReport,
} from 'src/constants/actionTypes'
import { usePolling } from 'src/utils/hooks/usePolling'
import { showToast } from 'src/utils/toast'
import { YardstikReport } from 'src/interfaces/yardstik'
import { OnboardingStatus, OnboardingStepType } from 'src/constants/onboarding'
import tw, { css } from 'twin.macro'
import { SvgIcon } from '@mui/material'
import { CancelMajor } from '@shopify/polaris-icons'
import { colors } from 'src/theme'

const YARDSTIK_VIEW_DRIVER_UPDATE_POLLING_INTERVAL = 1000 * 5
const DRIVER_UPDATE_POLLING_INTERVAL = 1000 * 30

export const useYardstik = (flow: 'onboarding' | 'recollect') => {
  const dispatch = useDispatch()

  const [yardstikReport, setYardstikReport] = useState<YardstikReport | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const startYardstik = useCallback(
    async (stepId: string) => {
      try {
        setIsLoading(true)

        const report: YardstikReport | undefined = await createAsyncAction(
          dispatch,
          flow === 'recollect'
            ? RecollectionFetchYardstikReport.request({ stepId })
            : OnboardingFetchYardstikReport.request({ stepId }),
        )
        if (report) {
          setYardstikReport(report)
        }
      } catch (err) {
        showToast('Failed to load background check page, please try again later', {
          variant: 'error',
        })
      } finally {
        setIsLoading(false)
      }
    },
    [flow],
  )

  const removeYardstikReport = useCallback(() => {
    setYardstikReport(null)
  }, [])

  const handleUpdateDriver = useCallback(async (stopPolling: () => void) => {
    const driver: OnboardingDriver | null = await createAsyncAction(
      dispatch,
      flow === 'recollect' ? RecollectionFetchDriver.request() : OnboardingFetchDriver.request(),
    )

    if (
      driver?.onboarding?.collectDataSteps?.some(
        (item) => item.type === OnboardingStepType.BGC && item.status !== OnboardingStatus.Created,
      )
    ) {
      stopPolling()
    }
  }, [])

  const { startPolling: yardstikViewStartPolling, stopPolling: yardstikViewStopPolling } =
    usePolling(handleUpdateDriver, YARDSTIK_VIEW_DRIVER_UPDATE_POLLING_INTERVAL)

  useEffect(() => {
    if (yardstikReport) {
      yardstikViewStartPolling()
    } else {
      yardstikViewStopPolling()
    }
  }, [yardstikReport])

  const { startPolling: pageRefreshStartPolling, stopPolling: pageRefreshStopPolling } = usePolling(
    handleUpdateDriver,
    DRIVER_UPDATE_POLLING_INTERVAL,
  )

  useEffect(() => {
    pageRefreshStartPolling()

    return () => {
      pageRefreshStopPolling()
    }
  }, [])

  const YardstikView = useMemo(
    () => () =>
      yardstikReport ? (
        <div css={[tw`absolute w-full h-full top-0 left-0 z-10`, styles.yardstikViewContainer]}>
          <iframe css={tw`w-full h-full border-0`} src={yardstikReport.applyLink} />
          <SvgIcon
            css={tw`absolute right-5 top-5 w-5 h-5 z-20 cursor-pointer`}
            onClick={removeYardstikReport}
          >
            <CancelMajor color={colors.GRAY_DARK_COOL} />
          </SvgIcon>
        </div>
      ) : null,
    [yardstikReport],
  )

  return {
    isYardstikLoading: isLoading,
    startYardstik,
    YardstikView,
  }
}

const styles = {
  yardstikViewContainer: css`
    background-color: ${colors.GRAY_WHITE};
  `,
}
