import { CollectDataFormItemType } from 'src/constants/collectData'
import { CollectDataFormInputItem } from 'src/interfaces/collectData'
import * as Yup from 'yup'

const validationMap = {
  [CollectDataFormItemType.TextInput]: Yup.string(),
  [CollectDataFormItemType.Date]: Yup.string().length(10, 'Date is invalid'),
  [CollectDataFormItemType.Number]: Yup.number().typeError('Must be a number'),
  [CollectDataFormItemType.Select]: Yup.string(),
  [CollectDataFormItemType.Radio]: Yup.string(),
  [CollectDataFormItemType.Checkbox]: Yup.array(),
  [CollectDataFormItemType.File]: Yup.string(),
}

const requiredMessages = {
  [CollectDataFormItemType.TextInput]: 'This field is required.',
  [CollectDataFormItemType.Date]: 'This field is required.',
  [CollectDataFormItemType.Number]: 'This field is required.',
  [CollectDataFormItemType.Select]: 'Selecting an answer is required.',
  [CollectDataFormItemType.Radio]: 'Selecting an answer is required.',
  [CollectDataFormItemType.Checkbox]: 'Selecting an answer is required.',
  [CollectDataFormItemType.File]: 'Please upload required file.',
}

export const getValidationSchema = (fields: Array<CollectDataFormInputItem>) => {
  const schemaObject: any = {}

  fields.forEach((item) => {
    schemaObject[item.id] = validationMap[item.type]

    if (item.required) {
      const requiredMessage = requiredMessages[item.type]

      if (item.type === CollectDataFormItemType.Checkbox) {
        schemaObject[item.id] = schemaObject[item.id].min(1, requiredMessage)
      } else {
        schemaObject[item.id] = schemaObject[item.id].required(requiredMessage)
      }
    }
  })

  return Yup.object().shape(schemaObject)
}
