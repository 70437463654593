import { Button, Typography } from '@mui/material'
import { colors } from 'src/theme'
import tw from 'twin.macro'
import { OnboardingPublicWrapper } from 'src/components/layout/onboarding/OnboardingPublicWrapper'
import { SignInForm } from 'src/components/auth/sign-in-form/SignInForm'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ONBOARDING_ROUTES } from 'src/constants/routes'
import { useCallback, useMemo } from 'react'
import { ChevronLeftMinor } from '@shopify/polaris-icons'
import { useSelector } from 'react-redux'
import { getPartnerPublicInfo } from 'src/selectors/partner'

export const OnboardingSignInPage = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()

  const partner = useSelector(getPartnerPublicInfo)

  const isContinue = useMemo(() => Boolean(location?.state?.isContinue), [location])

  const handleSuccess = (phone: string) => {
    navigate(`/onboarding/${params.partnerName}/enter-code`, { state: { phone } })
  }

  const handleBack = useCallback(() => {
    navigate(-1)
  }, [])

  if (!params?.partnerName) {
    return <Navigate to={ONBOARDING_ROUTES.NOT_FOUND_URL} />
  }

  return (
    <OnboardingPublicWrapper>
      {isContinue && (
        <Button
          css={tw`bg-[#F2E5FF] h-[42px] w-[82px] hover:bg-[rgba(226, 208, 244, 1)] mb-4`}
          onClick={handleBack}
        >
          <div css={tw`items-center flex flex-row`}>
            <ChevronLeftMinor fill={colors.PRIMARY_PURPLE_500} width={19} />
            <Typography color={colors.PRIMARY_PURPLE_500} css={tw`[font-weight: 600]`}>
              Back
            </Typography>
          </div>
        </Button>
      )}
      <Typography variant="h2" css={tw`mb-2`}>
        {`${isContinue ? 'Continue' : 'Begin'} your onboarding${
          partner?.displayName ? ` with ${partner?.displayName}` : ''
        }`}
      </Typography>
      <Typography css={tw`mb-6`} color={colors.GRAY_DARK_COOL}>
        {isContinue
          ? 'To proceed, please enter the phone number you used during the onboarding process.'
          : 'Securely log in with your phone number to swiftly upload documents for verification.'}
      </Typography>
      <SignInForm
        platform="onboarding"
        isContinue={isContinue}
        partnerName={params.partnerName}
        onSuccess={handleSuccess}
      />
    </OnboardingPublicWrapper>
  )
}
