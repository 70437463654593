import { Typography } from '@mui/material'
import { ChevronRightMinor, ClockMajor } from '@shopify/polaris-icons'
import { DefaultStyleType } from 'src/interfaces/styles'
import { colors } from 'src/theme'
import tw from 'twin.macro'

export interface InfoContainerProps {
  className?: DefaultStyleType
  title: string
  children: React.ReactNode
  onClick?(): void
}

export const InfoMultilineContainer = ({
  title,
  children,
  className,
  onClick,
}: InfoContainerProps) => {
  return (
    <div
      css={[
        onClick
          ? tw`bg-[rgba(242, 229, 255, 1)] hover:bg-[rgba(226, 208, 244, 1)] p-4 rounded-2xl flex flex-col cursor-pointer`
          : tw`bg-[rgba(242, 229, 255, 1)] p-4 rounded-2xl flex flex-col`,
        className,
      ]}
      onClick={onClick}
    >
      <div css={tw`flex flex-row content-center mb-1`}>
        <ClockMajor css={tw`w-5 h-5 mr-2`} fill={colors.PRIMARY_PURPLE_500} />
        <Typography variant="h4" color={colors.PRIMARY_PURPLE_500}>
          {title}
        </Typography>
      </div>
      <div>
        <div css={tw`flex flex-col`}>
          <Typography color={colors.PRIMARY_PURPLE_500}>{children}</Typography>
        </div>
      </div>
      {onClick ? (
        <div css={tw`ml-[16px] h-[20px]`}>
          <ChevronRightMinor width={20} fill={colors.PRIMARY_PURPLE_500} />
        </div>
      ) : null}
    </div>
  )
}
