import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { createAsyncAction } from 'src/utils/reduxUtils'
import {
  OnboardingFetchPersonaInquiry,
  OnboardingSetPersonaClientCompletedInquiry,
  OnboardingSetPersonaSessionToken,
  RecollectionFetchPersonaInquiry,
  RecollectionSetPersonaClientCompletedInquiry,
  RecollectionSetPersonaSessionToken,
} from 'src/constants/actionTypes'
import { showToast } from 'src/utils/toast'
import { PersonaInquiry } from 'src/interfaces/persona'
import Persona, { Client as PersonaClient } from 'persona'
import { captureSentryError } from 'src/utils/sentry'
import { InquiryError } from 'persona/dist/lib/interfaces'

export const usePersona = (flow: 'onboarding' | 'recollect') => {
  const dispatch = useDispatch()

  const personaClient = useRef<PersonaClient | null>(null)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePersonaCancel = useCallback(
    async (sessionToken: string | undefined, stepId: string) => {
      if (!sessionToken) {
        return
      }

      try {
        await createAsyncAction(
          dispatch,
          flow === 'recollect'
            ? RecollectionSetPersonaSessionToken.request({ sessionToken, stepId })
            : OnboardingSetPersonaSessionToken.request({ sessionToken, stepId }),
        )
      } catch (err) {
        captureSentryError(err, {
          message: '[handlePersonaCancel]',
        })
      }
    },
    [],
  )

  const handlePersonaError = useCallback((error: InquiryError) => {
    captureSentryError(error, {
      message: '[handlePersonaError]',
    })
  }, [])

  const handlePersonaComplete = useCallback(async (stepId: string) => {
    try {
      await createAsyncAction(
        dispatch,
        flow === 'recollect'
          ? RecollectionSetPersonaClientCompletedInquiry.request({ stepId })
          : OnboardingSetPersonaClientCompletedInquiry.request({ stepId }),
      )
    } catch (err) {
      showToast('Failed to complete persona, please try again later', {
        variant: 'error',
      })
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!personaClient.current) {
        return
      }

      personaClient.current.destroy()
      personaClient.current = null
    }
  }, [])

  const startPersona = useCallback(async (stepId: string) => {
    setIsLoading(true)

    try {
      const personaInquiry: PersonaInquiry | undefined = await createAsyncAction(
        dispatch,
        flow === 'recollect'
          ? RecollectionFetchPersonaInquiry.request({ stepId, flow: 'recollection' })
          : OnboardingFetchPersonaInquiry.request({ stepId, flow: 'onboarding' }),
      )

      if (!personaInquiry) {
        return
      }

      personaClient.current = new Persona.Client({
        // templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
        environmentId: process.env.REACT_APP_PERSONA_ENV_ID,
        inquiryId: personaInquiry.id,
        sessionToken: personaInquiry.sessionToken,
        onCancel: ({ sessionToken }) => handlePersonaCancel(sessionToken, stepId),
        onError: handlePersonaError,
        onComplete: () => handlePersonaComplete(stepId),
      })

      personaClient.current.open()
    } catch (err) {
      showToast('Failed to load persona, please try again later', {
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }, [])

  return { isPersonaLoading: isLoading, startPersona }
}
